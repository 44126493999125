<template lang="pug">
  div( :id="idModal" class="modal fade" :class="classOpen" role="dialog")
    .modal-dialog.modal-lg
      .modal-content
        .modal-header
          button(type="button" class="close" data-dismiss="modal")
            img(:src="img.close" width="12")
          p
            translate Solicitud de presupuesto
        .modal-body
          form.container(v-if="!isPublic" @submit.prevent="validateUser" @keyup.enter="validateUser")
            .row-flex
              .c-l
                span
                  img(:src="img.cotiza" width="128" alt="")
              .c-r(:class="{'show-thk': showThk}")
                .message-cn(v-if="message")
                  div(class="alert alert-warning" role="alert") {{message}}
                .solicitud-cn(v-if="!message")
                  p #[translate Hola],
                  p 
                    translate ¿Quieres solicitar un presupuesto para el producto
                    | 
                    strong {{product.name}}
                    | ?

                  p #[translate Déjanos un mensaje:]
                  #form-user
                    label #[translate Mensaje]:
                    textarea.form-control( :class="{ 'error':error.msg }" @focus="resetError" v-model="valuesUser.msg")

                .thank-cn
                  p
                    img(:src="img.check" width="15" alt="")
                    translate ¡Gracias por contactarnos!
                  p #[translate Hemos recibido correctamente tu solicitud y nuestro equipo contactará contigo lo antes posible].
            
            .row.submit-cn(v-if="!showThk && !message")
              .col-sm-6.text-right
                button.btn.btn-cancel(type="button" data-dismiss="modal") 
                  translate Cancelar
              .col-sm-6
                button.btn.btn-action(:class="{'btn-send': enviando}")
                  translate(v-if="!enviando") Enviar
                  translate(v-if="enviando") Enviando

            .row.submit-cn(v-if="showThk || message")
              .col-sm-12.text-center
                a.btn.btn-cerrar( data-dismiss="modal") 
                  translate Cerrar

          form.container(v-if="isPublic" @submit.prevent="validateNoUser" @keyup.enter="validateNoUser")
            .row-flex
              .c-l
                span
                  img(:src="img.cotiza" width="128" alt="")
              .c-r( :class="{'show-thk': showThk}")
                .message-cn(v-if="message")
                  div(class="alert alert-warning" role="alert") {{message}}
                .solicitud-cn(v-if="!message")
                  p #[translate Hola],
                  p 
                    translate ¿Quieres solicitar un presupuesto para el producto
                    | 
                    strong {{product.name}}
                    | ?

                  p #[translate Déjanos un mensaje:]
                  #form-user
                    p
                      label #[translate Nombre*]:
                      input.f_name.form-control( type="text" :class="{ 'error':error.nombre }" @focus="resetError" v-model="valuesUser.nombre" :placeholder="placeholder.nombre")
                    .group-two
                      p
                        label #[translate Email*]:
                        input.form-control( type="text" :class="{ 'error':error.email }" @focus="resetError" v-model="valuesUser.email" :placeholder="placeholder.email")
                      p
                        label #[translate Teléfono]:
                        input.form-control( type="text" v-model="valuesUser.tlf" :placeholder="placeholder.tlf")
                    .group-two
                      p
                        label #[translate Nombre de la empresa]:
                        input.form-control( type="text" v-model="valuesUser.companyName" :placeholder="placeholder.companyName")
                      .col-province.mt-0
                        label #[translate Provincia]*
                        .dropdown.Select-bk.mt-0
                          button.form-control(class="btn btn-default dropdown-toggle" :class="{ 'error':error.province }" type="button" id="ddProvince" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{province}}
                            i(class="fa fa-angle-down" aria-hidden="true")
                          ul(class="dropdown-menu" aria-labelledby="ddProvince")
                            li(v-for="item in provinces" :class="{'disabled': province.name == item.name}")
                              span( @click="changeProvinces(item)") {{item.name}}  
                    p
                      label #[translate Mensaje]:
                      textarea.form-control( :class="{ 'error':error.msg }" @focus="resetError" v-model="valuesUser.msg")

                    // vue-recaptcha(:sitekey="sitekey" :loadRecaptchaScript="true" ref="recaptcha" @verify="verifyRecaptcha")
                      button +{{sitekey}}+
                    
                    p.legal-cn(:class="{'error-legal': error.legal}")
                      input(type="checkbox" id="f-legal" checked)
                      |#[translate He leído y acepto los]&nbsp;
                      |
                      a(href="https://www.orbishigiene.com/wp-content/themes/orbishigiene/assets/doc/AvisoLegal.pdf" target="_blank") #[translate términos y condiciones]
                      |&nbsp;#[translate de uso]
                
                .thank-cn
                  p
                    img(:src="img.check" width="15" alt="")
                    translate ¡Gracias por contactarnos!
                  p #[translate Hemos recibido correctamente tu solicitud y nuestro equipo contactará contigo lo antes posible].
            
            .row.submit-cn(v-if="!showThk && !message")
              .col-sm-6.text-right
                button.btn.btn-cancel( type="button" data-dismiss="modal") #[translate Cancelar]
              .col-sm-6
                button.btn.btn-action(:class="{'btn-send': enviando}")
                  translate(v-if="!enviando") Enviar
                  translate(v-if="enviando") Enviando

            .row.submit-cn(v-if="showThk || message")
              .col-sm-12.text-center
                a.btn.btn-cerrar( data-dismiss="modal") 
                  translate Cerrar

</template>
<script>
// import VueRecaptcha from 'vue-recaptcha'

import SrvCatalog from '@/services/cotiza'

export default {
  name: 'ShippingModal',
  components: { },
  props: ['product', 'classOpen', 'type', 'provinces'], // clase para abrir el popup
  data () {
    return {
      'enviando': false,
      'message': '',
      'showThk': false, // true muestra las gracias.
      'error': {
        'show': false,
        'msg': false,
        'nombre': false,
        'email': false,
        'legal': false,
        'province': false
      },
      'placeholder': {
        'nombre': this.$gettext('Persona de contacto'),
        'email': this.$gettext('Email'),
        'tlf': this.$gettext('Número de teléfono'),
        'companyName': this.$gettext('Nombre de la empresa')
      },
      'img': {
        'close': require('@/assets/img/icos/cerrar-01.svg'),
        'check': require('@/assets/img/icos/check.svg'),
        'cotiza': require('@/assets/img/icos/cotiza_popup_02.svg')
      },
      'valuesUser': {},
      'province': this.$gettext('Provincia')
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    isPublic () {
      if (this.$router.currentRoute.name === 'catalogoListPublic' || this.$router.currentRoute.name === 'productSinglePublic') {
        return true
      } else {
        return false
      }
    },
    idModal () {
      if (this.type === 'single') {
        return 'modalCotizaSingle'
      }

      return `modalCotiza-${this.product.id}`
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    sitekey () {
      // site key del recaptcha
      return this.$config.configApp.sitekey_recaptcha
    }
  },
  created () {
  },
  methods: {
    validateUser () {
      const msg = this.valuesUser.msg
      if (msg === undefined || msg === '') {
        this.error.msg = true
        this.error.show = true
      } else {
        this.sendFormPrivate()
      }
    },
    validateNoUser () {
      const msg = this.valuesUser.msg
      const nombre = this.valuesUser.nombre
      const email = this.valuesUser.email
      const province = this.valuesUser.province
      if (msg === undefined || msg === '') {
        this.error.msg = true
        this.error.show = true
      }

      if (nombre === undefined || nombre === '') {
        this.error.nombre = true
        this.error.show = true
      }

      let RegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/
      if (email === undefined || email === '' || !email.match(RegEx)) {
        this.error.email = true
        this.error.show = true
      }

      if (province === undefined || province === '') {
        this.error.province = true
        this.error.show = true
      }
      // Comprobamos si tiene marcado el check legal
      const checkbox = document.getElementById('f-legal')
      if (checkbox.length && checkbox.checked === false) {
        this.error.legal = true
        return false
      }

      if (!this.error.show) {
        this.sendFormPublic()
      }
    },
    openModal () {
      // console.log('open Modal Cotiza')
      // console.log('product: ', this.product)
      this.showThk = false
      this.enviando = false
      this.message = ''
      this.valuesUser = {}
      this.resetError()
    },
    resetError () {
      this.error.show = false
      this.error.msg = false
      this.error.nombre = false
      this.error.email = false
      this.error.legal = false
      this.error.province = false
    },
    verifyRecaptcha (recaptchaToken) {
      console.log(`recaptchaToken: ${recaptchaToken}`)
    },
    sendFormPrivate () {
      if (!this.enviando) {
        this.enviando = true
        SrvCatalog.sendFormPrivate(this.product.id, this.valuesUser.msg)
          .then(res => {
            if (res.status === 201) {
              // console.log('------------ toSearch ---------------')
              // console.log('--->Producto: ', res.data.results)
              this.showThk = true
              this.enviando = false
            } else {
              this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
              this.enviando = false
            }
          }, () => {
            // console.log('----->' + error)
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            this.enviando = false
          })
      }
    },
    sendFormPublic () {
      if (!this.enviando) {
        this.enviando = true
        this.valuesUser.product = {'id': this.product.id}

        const data = {
          'name': this.valuesUser.nombre,
          'email': this.valuesUser.email,
          'phone': this.valuesUser.tlf,
          'business_name': this.valuesUser.companyName,
          'request_region': {
            'id': this.valuesUser.province
          },
          'text': this.valuesUser.msg,
          'product': {
            'id': this.product.id
          }
        }
        // this.valuesUser.recaptcha = ''
        SrvCatalog.sendFormPublic(data)
          .then(res => {
            if (res.status === 201) {
              // console.log('------------ toSearch ---------------')
              // console.log('--->Producto: ', res.data.results)
              this.showThk = true
              this.enviando = false
            } else {
              this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
              this.enviando = false
            }
          }, () => {
            // console.log('----->' + error)
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            this.enviando = false
          })
      }
    },
    changeProvinces (item) {
      this.province = item.name
      this.valuesUser.province = item.id
      this.resetError()
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal-content{
    border: 1px solid #17296E;
    @include roundAll(5);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
    box-shadow: 0 3px 6px rgba(0,0,0,.25);
    overflow: hidden;
  }
  .modal-header{
    background-color: #EBF3FE;
    padding-top:22px;
    padding-bottom: 22px;

    p{
      margin:0;
      text-transform: uppercase;
      text-align: left;
      font-size: 18px;
    }

    .close{
      margin-top: -22px;
      font-size: 40px;
      opacity: 1;
    }
  }

  .modal-body{
    padding: 38px 20px 38px 0;
    text-align: left;

    .row-flex{
      align-items: inherit;
    }

    .c-l{
      position: relative;
      z-index: 1;
      width: 15%;
      display: block;

      &:before{
        content:'';
        position: absolute;
        z-index: -1;
        top: 80px;
        bottom: 0;
        left:0;
        right: 0;
        background-color: #F5F7F9;
        @include roundAll(8);
      }
    }
    .c-r{
      position: relative;
      width: 80%;
      display: flex;
      align-items: center;

      p{
        line-height: 22px;
      }

      div{
        margin-top: 26px;
        &.col-province{
          display: flex;
          flex-direction: column;
          .dropdown.Select-bk{
            margin-left: 0;
            width: 170px;
            .dropdown-menu{
              width: 170px;
            }
          }
        }
        &.mt-0{
          margin-top: 0;
        }
      }

      label{
        font-weight: $bold;
        margin-bottom: 10px;
      }

      textarea{
        height: 96px;
      }

      .message-cn{
        margin: 0;
        width: 100%;
      }

      .solicitud-cn{
        position: relative;
        z-index: 2;
        margin-top:0;
        @include transition();

        strong{
          font-weight: $bold;
        }
      }

      .thank-cn{
        position: absolute;
        z-index: 1;
        top: 0;
        left:0;
        margin-top: 0;
        opacity:0;
        @include transition();
        p{
          padding: 30px 60px;
          &:first-child{
            background-color: #68CD86;
            color: #fff;
            text-transform: uppercase;
          }

          img{
            margin: 0 18px 0 -30px;
          }
        }
      }

      &.show-thk{
        .solicitud-cn{
          opacity: 0;
        }

        .thank-cn{
          opacity: 1;
        }
      }
    }
  }

  input:not([type="checkbox"]){
    margin-bottom: 32px;
  }

  .legal-cn{
    margin-top: 22px;
    input{
      margin-right: 8px;
    }
  }

  .f_name{
    width: 370px;
  }

  .group-two{
    display: flex;

    p{
      &:first-child{
        width: 270px;
        margin-right: 30px;
      }

      &:last-child{
        width: 170px;
      }
    }
  }

  .error-legal{
    color: $color-error;
    a{
      color: $color-error;
      text-decoration: underline;
    }
  }

  .submit-cn{
    border-top: 1px solid #ABC7F1;
    margin: 24px 0 0;
    padding-top: 24px;

    .btn{
      min-width: 170px;
    }
  }
</style>
