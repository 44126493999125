<template lang="pug">
  .ClientListItem
    .container

      .row.box-1
        .div(
            :class="{'col-sm-4': isTypeApp === 'pedrosa' || isTypeApp === 'maripol' || isTypeApp === 'inpacs', 'col-sm-3': isTypeApp === 'prisma' || isTypeApp === 'orbishigiene' || isTypeApp === 'ghgorbis' || isTypeApp === 'arablau'}"
            data-toggle="modal" data-target="#popupOrderCenter" @click="openPopup()"
          )
          span.img-cn
            img(:src="img.verCentro" width="14")
          p.name(v-if="order.center" :title="order.center.name") {{order.center.name}}

        .col-sm-1.num-cn(data-toggle="collapse" :data-target="'#demo-'+index" @click="actionShowDetalle")
          p.text-right {{order.number}}

        .col-sm-2.num-cn(data-toggle="collapse" :data-target="'#demo-'+index" @click="actionShowDetalle")
          p.text-right {{order.client_order_number}}

        .col-sm-1(v-if="isTypeApp !== 'pedrosa' && isTypeApp !== 'maripol' && isTypeApp !== 'inpacs'" data-toggle="collapse" :data-target="'#demo-'+index" @click="actionShowDetalle")
            p(class="text-center" :class="{'isBillable' : order.billable_center}") {{isBillableCenter}}

        .col-sm-1.date-cn( data-toggle="collapse" :data-target="'#demo-'+index" @click="actionShowDetalle")
          p.text-center {{order.order_datetime | moment("DD-MM-YYYY")}}

        .col-sm-1.date-cn( data-toggle="collapse" :data-target="'#demo-'+index" @click="actionShowDetalle")
          p.text-center(:title="order.delivery_datetime | moment('DD-MM-YYYY')") {{order.delivery_datetime | moment("DD-MM-YYYY")}}

        .col-sm-1.total-cn( data-toggle="collapse" :data-target="'#demo-'+index" @click="actionShowDetalle")
          p.text-right
            strong {{order.base | format-money}} {{order.currency_symbol}}

        .col-sm-2( data-toggle="collapse" :data-target="'#demo-'+index" @click="actionShowDetalle")
          p(class="text-center status-cn" :class="order.state") {{state}}
            img(:src="img.derecha" width="8" class="img-rotate")

      .row.box-2(:id="`demo-${index}`" class="collapse")
        .col-sm-12
          bk-grid(:id="order.id" :showDetalle="showDetalle")
</template>
<script>
import BkGrid from '@/components/order/mis-pedidos/listado-desplegable-grid.vue'

export default {
  name: 'OrderListItem',
  props: ['order', 'index'],
  components: { BkGrid },
  data () {
    return {
      showDetalle: false, // para que el componente BKGrid no haga la petición y traíga el detalle.
      img: {
        derecha: require('@/assets/img/icos/derecha.svg'),
        verCentro: require('@/assets/img/icos/ver-centro.svg')
      }
    }
  },
  created () {
  },
  computed: {
    state () {
      let result = '-'
      this.order.state_values.map(item => {
        // console.log(`code: ${item.code}, state: ${this.order.state}`)
        if (item.code === this.order.state) {
          result = item.value
        }
      })
      return result
    },
    isBillableCenter () {
      return this.order.billable_center ? this.$gettext('Si') : this.$gettext('No')
    },
    isClient () {
      return this.$store.getters.isClient
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  methods: {
    checkEmpty (v) {
      return (v === null || v === '') ? '-' : v
    },
    openPopup () {
      this.$emit('toOpenPopupOrderCenter', [this.order.center, this.order.client])
    },
    actionShowDetalle () {
      this.showDetalle = true
    }
  }
}
</script>
<style lang="scss" scoped>
  .ClientListItem{
    color:#494f58;
  }

  .box-1{
    @include transition();
    font-size: 14px;
    color: #4D4F5C;
    font-weight: $regular;
    border:1px solid #fff;
    cursor: pointer;

    & > *{
      padding-top:12px;
      padding-bottom:8px;
    }

    &:hover, &:focus{
      background-color: rgba(#129FE9, .2);
      border:1px solid #00ADFC;

      .name{
        font-weight: $medium;
      }
    }

    p{
      margin:0;

      &.name{
        text-transform: uppercase;
        padding-left: 48px;
        @include ellipsis();
        cursor: pointer;
        // margin-left:-15px;
        @include transition();
      }
    }
  }


  .client-cn{
    @include ellipsis();
  }

  .img-cn{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    height: 22px;
    margin: auto;
    padding-right: 40px;
  }

  .img-rotate{
    position: absolute;
    top: 5px;
    bottom: 0;
    right: 6px;
    height: 16px;
    margin: auto;
    @include transition();
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  [aria-expanded="true"]{
      .img-rotate{
        -moz-transform: rotate(90deg) translate(0px);
        -o-transform: rotate(90deg) translate(0px);
        -ms-transform: rotate(90deg) translate(0px);
        -webkit-transform: rotate(90deg) translate(0px);
        transform: rotate(90deg) translate(0px);
      }
  }

  .date-cn, .num-cn{
    padding-left:0;
    padding-right: 0;
  }

  .date-cn{
    p{
      @include ellipsis();
      display: block;
    }
  }

  .num-cn{
    p:empty{
      &:before{
        content: '-';
        margin-right: 60px;
      }
    }
  }

  .total-cn{
    strong{
      font-weight: $medium;
    }
  }

  .status-cn{
    color:$orange;
    &.normal{
      color: $color-text;
    }
    &.SERVIDO, &.S{
      color: $green;
    }
  }

  .box-2{
    padding: 16px 0 50px;
    background-color: #fff;
    border-bottom: 1px solid #F5F6FA;
    color: #4D4F5C;
    font-weight: $light;

    &.in{
      border-top: 1px solid $blue-light;
      border-bottom: 1px solid $blue-light;
    }

    strong{
      font-weight: $regular;
    }
  }

</style>
