<template lang="pug">
  .cart-list
    .box-1
      .container
        .row
          .col-sm-12
            p #[translate Carro]: {{numItems}} #[translate productos]
              button( class="btn btn-action" @click="goCatalog()") #[translate Añadir más productos]
              button( class="btn btn-white" @click="deleteCart()") #[translate Vaciar carro]
    .container
      .row
        .col-sm-9.section-cn
          div(v-if="messageError && messageError !== 1")
            p(class="alert alert-warning fade in" role="alert") {{messageError}}
          .head-list(v-if="items && items.length > 0")
            .big(:class="{'no-formato': !config_app.formato}") #[translate Artículos]
            .other.text-center #[translate Cantidad]
            .other.text-center(v-if="config_app.formato") #[translate Formato]
            .other.text-center(v-if="type_app === 'pedrosa'") &nbsp;
            .other.text-center(v-if="type_app !== 'pedrosa'") #[translate Descuento] %
            //.other.text-center(v-if="type_app === 'vario'") #[translate Margin]
            .other.text-center(v-if="type_app === 'vario' || type_app === 'pedrosa'") &nbsp;
            .other.text-center(v-if="type_app !== 'vario' && type_app !== 'pedrosa'") #[translate Facturable]
            .other.text-right #[translate Total]


          .item-list
            draggable( tag="div" :list="items" v-bind="dragOptions" @sort="changeSort" :sort="!dragdrop.senData")
              transition-group( type="transition" :name="!dragdrop.drag ? 'flip-list' : null")
                bk-item(v-for="item in items" :key="item.id" :product="item" :moneda="moneda" :hasFacturable="hasFacturable" @toRemoveProduct="toRemoveProduct" @toUpdateProduct="toUpdateProduct")

          .cargos-list( v-if=" role !== 'client' && type_app !== 'pedrosa'  && type_app !== 'maripol' && type_app !== 'vario' ")
            bk-cargos( :cargos="aCargos" :maxDiscount="custom_product_max_discount" @toSetCargo="toSetCargo")

          .box-shipping(v-if="type_app === 'kk'")
            .container
              .row.top-row
                .col-sm-2
                  img(:src="img.shipping")
                  translate Shipping

                .col-sm-4
                  p(class="volume-cn") #[translate Total volume of items]: {{volume.m}}m#[sup 3] / {{volume.kg}}kg.
                .col-sm-6
                  .shipping-free
                      p(v-if="!shipping.value")
                        img(:src="img.shippingFree")
                        | #[translate FREE SHIPPING]
                      p(v-else="")
                        img(:src="img.shippingFreeNo")
                        | #[translate FREE SHIPPING from]&nbsp{{moneda}}{{shipping.freeFrom | format-money}}
              .row
                .col-sm-2
                  p
                    button(data-toggle="modal" data-target=".modalShipping" class="btn btn-trans" @click="openModalShipping()") #[translate Shipping costs]
                .col-sm-4 &nbsp;
                .col-sm-6
                  .shipping-Select
                    p #[translate Shipping]:
                    .dropdown
                      button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPayment" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{shipping.optionSelect.name}}
                        i(class="fa fa-angle-down" aria-hidden="true")
                      ul(class="dropdown-menu" aria-labelledby="ddPayment")
                        li(v-for="item in shipping.options" :class="{'disabled': shipping.optionSelect.id == item.id} " :index="item.id")
                          span(:value="item.id" @click="changeShipping(item)") {{item.name}}
                    p {{shipping.optionSelect.price}} {{moneda}}
                  .delivery-date
                    label #[translate Estimated delivery date]:
                    date-picker(class="calendar-cn" :lang="calendar.lang" :format="calendar.format" type="day" :clearable="calendar.clearable" :shortcuts="calendar.shortcuts" :not-before="calendar.date" @input="changeCalendar" v-model="calendar.date")

          .box-payment(v-if="type_app === 'kk'")
            .container
              .row
                .col-sm-5
                  img(:src="img.payment")
                  translate Payment
                .col-sm-4.text-right
                  strong #[translate Payment options]:
                  .dropdown
                    button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPayment" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{payment.optionSelect.name}}
                      i(class="fa fa-angle-down" aria-hidden="true")
                    ul(class="dropdown-menu" aria-labelledby="ddPayment")
                      li(v-for="item in payment.options" :class="{'disabled': payment.optionSelect.id == item.id} " :index="item.id")
                        span(:value="item.id" @click="changePayment(item)") {{item.name}}
                .col-sm-3
                  strong #[translate Discount]
                  em -x,xx {{moneda}}

          .container.box-2(v-if="type_app === 'orbishigiene'")
            .row
              .col-sm-12
                div
                  p #[translate Nº Pedido cliente]:
                    input.nPedidoCliente(type="text" v-model="nPedidoCliente" maxlength="10")
                    span #[translate Fecha de entrega]:
                    date-picker(class="calendar-cn" style="margin-left:0" :lang="pickupDate.lang" :format="pickupDate.format" type="day" :clearable="pickupDate.clearable" :shortcuts="pickupDate.shortcuts" :not-before="pickupDate.date" v-model="pickupDate.date")

          .container.box-2
            .row
              .col-sm-8.info-centro
                div
                  p #[translate Centro]:
                  p
                    button(data-toggle="modal" data-target=".modalCart" class="btn btn-trans" @click="openModal()") #[translate Cambiar centro]
                div
                  p(v-html="centerLabel")


              .col-sm-4
                ul
                  li #[translate Subtotal]: #[small {{base | format-money}} {{moneda}}]
                  li(v-if="client_discount_percentage>0") #[translate Descuento]: #[small {{client_discount_percentage | format-money}}%]
                  li(v-if="client_discount_percentage>0") #[translate Importe final]: #[small {{final | format-money}} {{moneda}}]
                  li #[translate IVA]: #[small {{tax | format-money}} {{moneda}}]
                  li #[translate TOTAL] #[small {{total | format-money}} {{moneda}}]


          .container.box-3(v-if="!clientLocked && type_app === 'vario'" class="type-vario")
            .row
              .col-sm-8.c-l
                p(style="display: none;")
                  label #[translate Attach file (PO)]
                  input(type="file")


                p
                  label #[translate Comments]
                  textarea.form-control(:placeholder="placeholder.observaciones" v-model="observations")

              .col-sm-4.c-r
                div(v-if="this.role !== 'client'")
                  div
                    span #[translate Finalizar como]:
                    .dropdown.Select-bk
                      button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPortes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{option}}
                        i(class="fa fa-angle-down" aria-hidden="true")
                      ul(class="dropdown-menu" aria-labelledby="ddPortes")
                        li(v-for="item in options" :class="{'disabled': option == item.name}")
                          span(:value="item.id" @click="changeOption(item.id, item.name)") {{item.name}}
                  transition(name="fade")
                    div(v-if="optionId == 2")
                      span #[translate Enviar como]:
                      .dropdown.Select-bk
                        button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPrintFormats" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{printFormatsName}}
                          i(class="fa fa-angle-down" aria-hidden="true")
                        ul(class="dropdown-menu" aria-labelledby="ddPrintFormats")
                          li(v-for="item in printFormats" :class="{'disabled': printFormatsName == item.name}")
                            span(:value="item.id" @click="changeOptionPrintFormats(item.id, item.name)") {{item.name}}

            .row-flex
              p(:class="{'error-legal': error.legal}")
                input(type="checkbox" id="f-legal" checked)
                |#[translate Our general]&nbsp;
                |
                a(href="#" target="_blank") #[translate terms and conditions]
                |&nbsp;#[translate apply. These include our privacy policy].
              p.submit-cn
                button(class="btn btn-cancel" @click="goCatalog()") #[translate Cancelar]
                button(class="btn btn-action" :class="{'btn-disabled': !numItems || !optionId, 'btn-send': enviando}" @click="finalizarPedido()")
                  span(v-if="!enviando") #[translate Enviar]
                  span(v-if="enviando") #[translate Enviando]

          .container.box-3(v-if="!clientLocked && type_app !== 'vario'")
            .row
              .col-sm-7.obser-cn
                label #[translate Observaciones]:
                textarea.form-control(:placeholder="placeholder.observaciones" v-model="observations")

                // Punchout form
                div(v-if="isPunchout")
                  form(method="post" ref="punchoutForm" :action="punchoutPostUrl")
                    input(type="hidden" name="cxml-urlencoded" :value="punchoutPostXml")
                    input(type="submit" ref="punchoutSubmit" style="visibility: hidden")

              .col-sm-5.text-right
                div(v-if="this.role !== 'client' && (type_app === 'prisma' || (config_app.catalog_generator && (type_app === 'maripol' || type_app === 'pedrosa'))) && !isNetwork")
                  div
                    span #[translate Finalizar como]:
                    .dropdown.Select-bk
                      button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPortes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{option}}
                        i(class="fa fa-angle-down" aria-hidden="true")
                      ul(class="dropdown-menu" aria-labelledby="ddPortes" v-if="(type_app === 'prisma')")
                        li(v-for="item in optionsPrisma" :class="{'disabled': optionPrisma == item.name}")
                          span(:value="item.id" @click="changeOption(item.id, item.name)") {{item.name}}

                      ul(class="dropdown-menu" aria-labelledby="ddPortes" v-if="(type_app === 'maripol') ")
                        li(v-for="item in optionsMaripol" :class="{'disabled': optionMaripol == item.name}")
                          span(:value="item.id" @click="changeOption(item.id, item.name)") {{item.name}}

                      ul(class="dropdown-menu" aria-labelledby="ddPortes" v-if="(type_app === 'pedrosa') ")
                        li(v-for="item in optionsPedrosa" :class="{'disabled': optionPedrosa == item.name}")
                          span(:value="item.id" @click="changeOption(item.id, item.name)") {{item.name}}

                  transition(name="fade")
                    div(v-if="optionId == 2")
                      span #[translate Enviar como]:
                      .dropdown.Select-bk
                        button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPrintFormats" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{printFormatsName}}
                          i(class="fa fa-angle-down" aria-hidden="true")
                        ul(class="dropdown-menu" aria-labelledby="ddPrintFormats")
                          li(v-for="item in printFormats" :class="{'disabled': printFormatsName == item.name}")
                            span(:value="item.id" @click="changeOptionPrintFormats(item.id, item.name)") {{item.name}}
              // SI TENEMOS GENERADOR CATÁLOGOS
              transition(name="fade")
                div(v-if="config_app.catalog_generator && this.role !== 'client' && optionId == 3")
                  .col-sm-12.text-right
                  .col-sm-2
                  .col-sm-10
                    div.col-sm-12#opcion-carrito-catalogo-pdf
                      .row(v-if="type_app !== 'pedrosa'")
                        .col-sm-6
                          p Si selecciona #[strong Generar Catálogo en PDF] se enviará el tipo seleccionado en PDF al email: {{ user.email }}
                        .col-sm-6.text-right
                          span #[translate Tipo de catálogo]:
                          .dropdown.Select-bk
                            button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPortes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{optionCatalog}}
                              i(class="fa fa-angle-down" aria-hidden="true")
                            ul(class="dropdown-menu" aria-labelledby="ddPortes")
                              li(v-for="(value, key)  in catalogs_types" :class="{'disabled': option == key}")
                                span(:value="value" @click="changeOptionCatalogs(key, value)") {{value}}
                      .row(v-if="type_app === 'pedrosa'")
                        .col-sm-12
                          p Si selecciona #[strong Generar Oferta en PDF] se enviará el tipo seleccionado en PDF al email: {{ user.email }}
                        .col-sm-6.text-right
                          span #[translate Tipo de Portada]:
                          .dropdown.Select-bk
                            button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPortes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{optionCatalog}}
                              i(class="fa fa-angle-down" aria-hidden="true")
                            ul(class="dropdown-menu" aria-labelledby="ddPortes")
                              li(v-for="(value, key) in catalogs_types" :class="{'disabled': option == key}")
                                span(:value="value" @click="changeOptionCatalogs(key, value)") {{value}}
                        .col-sm-6.text-right(v-if="optionCatalogId !== 'budgetwf' && optionCatalogId !== 'budgetwof' ")
                          span #[translate Tipo de Página]:

                          .dropdown.Select-bk
                            button.form-control(class="btn btn-default dropdown-toggle" type="button" id="perPage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{optionCatalogItemPerPage}}
                              i(class="fa fa-angle-down" aria-hidden="true")
                            ul(class="dropdown-menu" aria-labelledby="perPage")
                              li(v-for="(value, key) in catalogs_items_per_page" :class="{'disabled': option == key}")
                                span(:value="value" @click="changeOptionCatalogsItemsPerPage(key, value)") {{value}}
                      .row
                        .col-sm-1
                        .col-sm-4.text-right
                          label.mt-6.tr(for="nombrecatalogo")(v-if="type_app !== 'pedrosa'") #[translate Nombre de la Catálogo]:
                          label.mt-6.tr(for="nombrecatalogo")(v-if="type_app === 'pedrosa'") #[translate Nombre de la Oferta]:
                        .col-sm-7.text-right
                          input#nombrecatalogo.form-control(v-model="catalogName")
                      .row.fileInputName(v-if="optionCatalogId !== 'budgetwof'")
                        .col-sm-1
                        .col-sm-4.text-right
                          div.text-right
                            p.mt-10 #[translate Añadir logotipo del cliente ]:
                        .col-sm-7.text-right
                            label.input-group-btn.w-20.d-table-cell
                              span.btn.btn-default.txt-c-blue #[translate Seleccionar archivo]
                                input(type="file" v-show="false" @change="previewFiles" accept=".png, .jpg")
                            input.w-80.btn.btn-default.form-control.showFileStatus.text-xs.gtext-left(type="text" :value="fileInputName" readonly)
                  .col-sm-12.text-right
                    p.submit-cn(v-if="type_app !== 'pedrosa'")
                      button(class="btn btn-cancel" @click="goCatalog()") #[translate Cancelar]
                      button(class="btn btn-action" :class="{'btn-disabled': !numItems || !optionId || (config_app.catalog_generator && optionId == 3 && (!optionCatalogId || !catalogName)), 'btn-send': enviando}" @click="finalizarPedido()")
                        span(v-if="!enviando") #[translate Enviar]
                        span(v-if="enviando") #[translate Enviando]
                    p.submit-cn(v-if="type_app === 'pedrosa'")
                      button(class="btn btn-cancel" @click="goCatalog()") #[translate Cancelar]
                      button(v-if="optionCatalogId === 'budgetwf' || optionCatalogId === 'budgetwof'" class="btn btn-action" :disabled="!catalogName" :class="{'btn-disabled': !catalogName,'btn-send': enviando}" @click="finalizarPedido()")
                        span(v-if="!enviando") #[translate Enviar]
                        span(v-if="enviando") #[translate Enviando]
                      button(v-else class="btn btn-action" :disabled="!numItems || !optionId || (config_app.catalog_generator && optionId == 3 && (!optionCatalogId || !catalogName))" :class="{'btn-disabled': !numItems || !optionId || (config_app.catalog_generator && optionId == 3 && (!optionCatalogId || !catalogName)), 'btn-send': enviando}" @click="finalizarPedido()")
                        span(v-if="!enviando") #[translate Enviar]
                        span(v-if="enviando") #[translate Enviando]
                div(v-else-if="type_app == 'prisma' && optionId == 2")
                  .col-sm-12.text-right
                      p.submit-cn
                        button(class="btn btn-cancel" @click="goCatalog()") #[translate Cancelar]
                        button(class="btn btn-action" :class="{'btn-disabled': !numItems || !optionId, 'btn-send': enviando}" @click="finalizarPedido()")
                          span(v-if="!enviando") #[translate Enviar]
                          span(v-if="enviando") #[translate Enviando]
                div(v-else)
                  .col-sm-5.text-right
                    p.submit-cn
                      button(class="btn btn-cancel" @click="goCatalog()") #[translate Cancelar]
                      button(class="btn btn-action" :class="{'btn-disabled': !numItems || !optionId, 'btn-send': enviando}" @click="showOthersProductsOrEndOrder()")
                        span(v-if="!enviando") #[translate Enviar]
                        span(v-if="enviando") #[translate Enviando]

        .col-sm-3.aside-cn
          div.aside-box
            h4 #[translate Resumen carro]:
            ul
              li {{numItems}} #[translate Productos]: #[small {{base | format-money}} {{moneda}}]
              li(v-if="client_discount_percentage>0") #[translate Descuento]: #[small {{client_discount_percentage | format-money}}%]
              li(v-if="client_discount_percentage>0") #[translate Importe final]: #[small {{final | format-money}} {{moneda}}]
              li #[translate IVA]: #[small {{tax | format-money}} {{moneda}}]
              li #[translate TOTAL] #[small {{total | format-money}} {{moneda}}]
          div.aside-box
            h5
              translate Centro
              button(data-toggle="modal" data-target=".modalCart" class="btn btn-trans" @click="openModal()") #[translate Cambiar centro]
            p(v-html="centerLabel")
            p.hidden
              button(data-toggle="modal" data-target="#modal-cart-common" class="btn-modal" ref="showModal")
            p.hidden
              button(data-toggle="modal" data-target="#modal-others-products" class="btn-modal" ref="showModalOthersProducts")

          div(v-if="appConfig.featureFlags.carbonFootprintSideBox")
            finger-print-side-box

    .info-pedido-ok(v-if="messageShow" class="fade in" :class="{'error': messageError}")

      div(v-if="optionId === 1 && messageError !== 1")
        // cuando son pedidos.
        p
          i(class="fa fa-check" aria-hidden="true")
        p
          strong #[translate El pedido se ha realizado con éxito].
        // p #[translate Nº de Pedido: 21724]
        p #[translate Puede ver el historial de pedidos en]
          router-link( :to="{ name: 'pedidosList'}" class="" ) #[translate Mis pedidos]
        p
          router-link( :to="{ name: config_app.url_home}" class="btn btn-action" ) #[translate Volver al inicio]

      div(v-else-if="optionId === 1 && messageError === 1")
        // cuando son pedidos y supera el límite
        p
          img(:src="img.alerta" width="70" height="70")
        p #[translate Este pedido supera el límite de consumo de este centro].
          br
          | #[El pedido pasa a estar pendiente de validación].
        p #[translate Puede verlo en]
          router-link( :to="{ name: 'validarPedidos'}" class="" ) #[translate Validar pedidos]
        p
          router-link( :to="{ name: config_app.url_home}" class="btn btn-action" ) #[translate Volver al inicio]

      div(v-else-if="optionId === 2 && messageError !== 1")
        // cuando son ofertas.
        p
          i(class="fa fa-check" aria-hidden="true")
        p
          strong #[translate La Oferta se ha realizado con éxito].
        // p #[translate Nº de Oferta: 21724]
        p #[translate Puede ver el historial de ofertas en]
          router-link( :to="{ name: 'ofertasList'}" class="" ) #[translate Ofertas]
        p
          router-link( :to="{ name: config_app.url_home}" class="btn btn-action" ) #[translate Volver al inicio]

      div(v-else-if="optionId === 2 && messageError === 1")
        // cuando son ofertas y supera el límite
        p
          img(:src="img.alerta" width="70" height="70")
        p #[translate Esta oferta supera el límite de consumo de este centro].
          br
          | #[La oferta pasa a estar pendiente de validación].
        p #[translate Puede verlo en]
          router-link( :to="{ name: 'validarOfertas'}" class="" ) #[translate Validar ofertas]
        p
          router-link( :to="{ name: config_app.url_home}" class="btn btn-action" ) #[translate Volver al inicio]

      div(v-else-if="optionId === 3 && type_app !== 'pedrosa' && messageError !== 1")
        // cuando se genera catálogo.
        p
          i(class="fa fa-check" aria-hidden="true")
        p
          strong #[translate El catálogo se ha realizado con éxito].
        // p #[translate Nº de catálogo: 21724]
        p #[translate Puede ver el historial de catálogos en]
          router-link( :to="{ name: 'catalogGeneratorList'}" class="" ) #[translate Mis catálogos]
        p
          router-link( :to="{ name: config_app.url_home}" class="btn btn-action" ) #[translate Volver al inicio]

      div(v-else-if="optionId === 3 && type_app === 'pedrosa' && messageError !== 1")
        // cuando son ofertas tipo catálogo pdf en pedrosa.
        p
          i(class="fa fa-check" aria-hidden="true")
        p
          strong #[translate La Oferta se ha realizado con éxito].
        // p #[translate Nº de Oferta: 21724]
        p #[translate Puede ver el historial de ofertas en]
          router-link( :to="{ name: 'catalogGeneratorList'}" class="" ) #[translate Ofertas]
        p
          router-link( :to="{ name: config_app.url_home}" class="btn btn-action" ) #[translate Volver al inicio]


    bk-modal(ref="modalCartCommon" id="modal-cart-common" :title="modal.title" :content="modal.content" :footer="modal.footer")
    bk-modal-center(ref="modalCenter" classOpen="modalCart")
    bk-modal-shipping(ref="modalShipping" classOpen="modalShipping")
    bk-modal-others-products(ref="modalOthersProducts" id="modal-others-products" :aProduct="othersProducts" @endOrder="finalizarPedido")

</template>
<script>
import appConfig from '@/config-app.js'
import BkItem from '@/components/catalog/Item-cart.vue'
import BkCargos from '@/components/catalog/Cargos-list.vue'
import BkModal from '@/components/common/Modal.vue'
import BkModalCenter from '@/components/center/Model-content.vue'
import BkModalShipping from '@/components/common/Modal-shipping.vue'
import draggable from 'vuedraggable'
import DatePicker from 'vue2-datepicker'
import BkModalOthersProducts from '@/components/common/Modal-others-products.vue'

// importamos servicios
import SrvCatalog from '@/services/catalog'
import SrvOfertas from '@/services/ofertas'
import SrvClients from '@/services/clients'

import {mapGetters} from 'vuex'
import FingerPrintSideBox from "@/components/finger-print/FingerPrintSideBox";

export default {
  name: 'CartList',
  components: {FingerPrintSideBox, BkItem, BkCargos, BkModal, BkModalCenter, BkModalShipping, draggable, DatePicker, BkModalOthersProducts },
  data () {
    return {
      appConfig: appConfig,
      option: this.$gettext('Selecciona'),
      optionPrisma: this.$gettext('Selecciona'),
      optionMaripol: this.$gettext('Selecciona'),
      optionPedrosa: this.$gettext('Selecciona'),
      optionId: 0,
      // options id 3 reservado generador catalogo
      options: [
        {'id': 0, 'name': this.$gettext('Selecciona')},
        {'id': 1, 'name': this.$gettext('Pedido')},
        {'id': 2, 'name': this.$gettext('Oferta')}
      ],
      optionsPrisma: [
        {'id': 0, 'name': this.$gettext('Selecciona')},
        {'id': 1, 'name': this.$gettext('Pedido')},
        {'id': 2, 'name': this.$gettext('Oferta')}
      ],
      optionsMaripol: [], // se carga en addOptions
      optionsPedrosa: [], // se carga en addOptions
      optionCatalog: this.$gettext('Selecciona'),
      optionCatalogId: 0,
      optionCatalogItemPerPage: this.$gettext('Selecciona'),
      optionCatalogItemPerPageId: 0,
      catalogName: null,
      aCargos: [{}],
      observations: '',
      messageError: null,
      messageShow: null,
      enviando: false,
      printFormats: [],
      printFormatsName: this.$gettext('Selecciona'),
      printFormatsId: null,
      isPunchout: false,
      punchoutPostUrl: null,
      punchoutPostXml: null,
      shipping: {
        // TODO: conectarlo a la API: https://3.basecamp.com/4056515/buckets/13942681/todos/2153920137
        'freeFrom': 90.39,
        'value': 10, // Si es 0 el envío es gratis
        // TODO: Que estos datos los coja de la API: https://3.basecamp.com/4056515/buckets/13942681/todos/2237497080
        'optionSelect': {'id': 0, 'name': '', 'price': 0},
        'options': [
          {
            'id': 1,
            'name': this.$gettext('3PL (free)'),
            'price': 0
          },
          {
            'id': 2,
            'name': this.$gettext('Self picking (10 €)'),
            'price': 10
          },
          {
            'id': 3,
            'name': this.$gettext('Saturday delivery (10 €)'),
            'price': 10
          },
          {
            'id': 4,
            'name': this.$gettext('EXW (free)'),
            'price': 0
          }
        ]
      },
      volume: {
        // TODO: conectarlo a la API: https://3.basecamp.com/4056515/buckets/13942681/todos/2236275482
        m: 10,
        kg: 20
      },
      placeholder: {
        'observaciones': this.$gettext('Observaciones')
      },
      modal: {
        // show: false,
        title: '',
        content: '',
        footer: ''
      },
      img: {
        alerta: require('@/assets/img/icos/alerta-01.svg'),
        payment: require(`@/assets/img/icos/payment.svg`),
        shipping: require(`@/assets/img/icos/shipping.svg`),
        shippingFree: require(`@/assets/img/icos/shipping_free.svg`),
        shippingFreeNo: require(`@/assets/img/icos/shipping_free_no.svg`)
      },
      dragdrop: {
        drag: true,
        senData: false // Si es true, no deja arrastrar. Lo usamos para que cuando se mande la nueva ordenación no se pueda cambiar.
      },
      error: {
        legal: false
      },
      payment: {
        // TODO: Que estos datos los coja de la API
        'optionSelect': {'id': 0, 'name': ''},
        'options': [
          {
            'id': 1,
            'name': this.$gettext('Credito')
          },
          {
            'id': 2,
            'name': this.$gettext('Pago anticipado')
          },
          {
            'id': 3,
            'name': this.$gettext('En efectivo')
          },
          {
            'id': 4,
            'name': this.$gettext('Pago a la entrega')
          }
        ]
      },
      'calendar': {
        'date': this.$moment().format('YYYY-MM-DD'),
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'DD/MM/YYYY' // 'MMM YYYY'
      },
      nPedidoCliente: null,
      'pickupDate': {
        'date': null,
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'DD/MM/YYYY' // 'MMM YYYY'
      },

      fileInputName: this.$gettext('Ninguno seleccionado'),
      customerLogo: null,
      othersProducts: []
    }
  },
  created () {
    this.getPrintFormats()
    this.addOptions()
    // si es cliente solo le dejamos la opción de pedidos o no es Prisma (Prisma es el único que puede poner ofertas.)
    if (this.role === 'client' || this.type_app !== 'prisma' || this.isNetwork) {
      this.option = this.$gettext('Pedido')
      this.optionId = 1
    } else {
      this.option = this.$gettext('Selecciona')
      this.optionId = 0
    }
    if (this.config_app.catalog_generator) {
      this.optionsCatalogs = this.$store.getters.cart.catalogs_types
      this.optionsCatalogsItemsPerPage = this.$store.getters.cart.catalogs_items_per_page
    }
    // console.log(`----> Role: ${this.role}`)

    // Activar o desactivar el poder arrastrar los productos en el carrito.
    this.dragdrop.senData = !this.config_app.sort_cart_drag

    // Cogemos la primera opción de la forma de pago.
    this.payment.optionSelect = this.payment.options[0]

    // Cogemos la primera opción de envio.
    this.shipping.optionSelect = this.shipping.options[0]

    if (this.type_app === 'orbishigiene') {
      if (window.localStorage.getItem('_buyerCookie')) {
        this.isPunchout = true
      } else {
        this.isPunchout = false
      }
    }
  },
  mounted () {
    if (this.role === 'admin' || this.role === 'sales_person' || this.role === 'client') {
      this.getOthersProducts()
    }
    if (this.client) {
      this.getClient()
    }
  },
  computed: {
    ...mapGetters([
      'role'
    ]),
    numItems () {
      if (this.items) {
        return this.items.length
      }
      return 0
    },
    client () {
      return this.$store.getters.client
    },
    user () {
      return this.$store.getters.user
    },
    clientLocked () {
      if (this.client) {
        return this.client.locked
      }

      return false
    },
    center () {
      return this.$store.getters.center
    },
    centerLabel () {
      if (this.center) {
        return `${this.center.name}<br> ${this.center.address}<br> ${this.center.locality}`
      } else {
        return '-'
      }
    },
    hasFacturable () {
      // console.log('----> center: ', this.center)
      if (this.center) {
        return this.center.has_facturable
      }

      return null
    },
    role () {
      return this.$store.getters.role
    },
    // CART
    cart () {
      return this.$store.getters.cart
    },
    catalogs_types () {
      return this.cart.catalogs_types
    },
    catalogs_items_per_page () {
      return this.cart.catalogs_items_per_page
    },
    idCart () {
      return this.cart.idCart
    },
    base () {
      return this.cart.base
    },
    discounts () {
      return this.cart.discounts
    },
    tax () {
      return this.cart.tax
    },
    total () {
      return this.cart.total
    },
    moneda () {
      return this.cart.moneda
    },
    isNetwork () {
      return this.cart.is_network
    },
    items () {
      return this.cart.items
    },
    client_discount_percentage () {
      return this.cart.client_discount_percentage
    },
    client_discount () {
      return this.cart.client_discount
    },
    custom_product_max_discount () {
      return this.cart.custom_product_max_discount
    },
    final () {
      return this.cart.final
    },
    type_app () {
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    showOthersProductsOrEndOrder () {
      if ((this.role === 'admin' || this.role === 'sales_person' || this.role === 'client') && this.optionId === 1 && this.othersProducts.length > 0 && !this.$store.getters.getModalOthersProductsIsView) {
        this.showModalOthersProducts()
      } else {
        this.finalizarPedido()
      }
    },
    showModalOthersProducts (msg, imagen, textoBoton) {
      // En el HTML hay un botón con el atributo ref="showModal" y de esta forma le hacemos click para mostrar la modal.
      this.$refs.showModalOthersProducts.click()
    },
    getOthersProducts () {
      // query, page, this.idCat, this.complete, this.orderby, this.only_usual_products, this.is_outlet, this.is_eco, this.add_extra_products
      SrvCatalog.getOthersProducts()
        .then(res => {
          if (res.status === 200) {
            this.othersProducts = res.data.results
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            this.codeControlInfo = 'CL-GOP-01'
          }
        }, () => {
          // console.log('----->' + error)
          this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          this.codeControlInfo = 'CL-GOP-02'
        })
    },
    addOptions () {
      if (this.type_app === 'maripol') {
        this.optionsMaripol.push({'id': 0, 'name': this.$gettext('Selecciona')})
        this.optionsMaripol.push({'id': 1, 'name': this.$gettext('Pedido')})
      }
      if (this.type_app === 'pedrosa') {
        this.optionsPedrosa.push({'id': 0, 'name': this.$gettext('Selecciona')})
        this.optionsPedrosa.push({'id': 1, 'name': this.$gettext('Pedido')})
      }
      if (this.$config.configApp.catalog_generator) {
        this.optionsPrisma.push({'id': 3, 'name': this.$gettext('Generar catálogo en PDF')})
        this.optionsMaripol.push({'id': 3, 'name': this.$gettext('Generar catálogo en PDF')})
        this.optionsPedrosa.push({'id': 3, 'name': this.$gettext('Oferta')})
        this.options.push({'id': 3, 'name': this.$gettext('Generar catálogo en PDF')})
      }
    },
    openModal () {
      this.$refs.modalCenter.openModal()
    },
    openModalShipping () {
      this.$refs.modalShipping.openModal()
    },
    changeOption (id, name) {
      // console.log(`----- ${v}`)
      this.option = name
      this.optionId = id
      // Si cambiaomos una opción de finalizar. Vaciamos el printFormatsId
      this.printFormatsId = null
      if (this.optionId !== 3) {
        this.optionCatalogId = null
        this.optionCatalogItemPerPageId = null
      }
    },
    changeOptionCatalogs (id, name) {
      // console.log(`----- ${v}`)
      this.optionCatalog = name
      this.optionCatalogId = id
    },
    changeOptionCatalogsItemsPerPage (id, name) {
      // console.log(`----- ${v}`)
      this.optionCatalogItemPerPage = name
      this.optionCatalogItemPerPageId = id
    },
    changeOptionPrintFormats (id, name) {
      // console.log(`----- ${v}`)
      this.printFormatsName = name
      this.printFormatsId = id
    },
    changePayment (item) {
      // TODO que esta opción se envie a la API al finalizar el pedidos.
      this.payment.optionSelect = item
    },
    changeShipping (item) {
      // TODO que esta opción se envie a la API al finalizar el pedidos.
      this.shipping.optionSelect = item
    },
    goCatalog () {
      this.$router.push({name: this.config_app.url_home})
    },
    setCart (item) {
      // console.log(`Añadir product:`, item)
      this.$store.dispatch('addCart', {idProduct: item.product, quantity: item.quantity, product: item})
    },
    deleteCart () {
      this.$store.dispatch('deleteCart')
      this.messageError = this.$gettext('Carro eliminado.')
    },
    toRemoveProduct (product) {
      // console.log(`Remove product: ${product.id}`)
      this.$store.dispatch('removeCartProduct', product)
    },
    toUpdateProduct (product) {
      // quitamos el emnsaje de error si existe
      this.messageError = ''
      // console.log(`update product:`, product)
      SrvCatalog.updateProduct(product)
        .then(res => {
          // console.log(res)
          // Actualizamos el carrito.
          this.$store.dispatch('getCart')
        }, (error) => {
          // console.log(error.data.discount + '----->', error)

          if (error.status === 400 && error.data.discount !== undefined) {
            this.messageError = error.data.discount[0]
          } else if (error.status === 400) {
            this.messageError = error.data
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        })
    },
    toSetCargo (cargo) {
      // console.log(`Cargo - CartList Añadir carros.`, cargo)
      this.setCart(cargo)
    },
    getPrintFormats () {
      SrvOfertas.getPrintFormats()
        .then(res => {
          // console.log('getPrintFormats: ', res)
          if (res.status === 200) {
            const data = res.data
            // console.log('getPrintFormats: ', data)
            this.printFormats = data.results
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            window.scrollTo(0, 0)
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          window.scrollTo(0, 0)
        })
    },
    finalizarPedido () {
      this.messageError = null
      this.messageShow = null
      this.error.legal = false
      let error = false

      // Si no hay centro seleccionado, es un pedido y es vario se muestra el popup
      if (!this.center && this.optionId === 1) {
        const msg = this.$gettext('Para realizar un Pedido, tiene que seleccionar un centro.')
        this.showModalMsg(msg)
        return false
      } else if (!this.center && this.optionId === 3 && this.type_app !== 'pedrosa') {
        const msg = this.$gettext('Para realizar un Catálogo, tiene que seleccionar un centro.')
        this.showModalMsg(msg)
        return false
      } else if (!this.center && this.optionId === 3 && this.type_app === 'pedrosa') {
        const msg = this.$gettext('Para realizar una Oferta, tiene que seleccionar un centro.')
        this.showModalMsg(msg)
        return false
      }

      // Comprobamos si tiene marcado el check legal
      const checkbox = document.getElementById('f-legal')
      if (checkbox && checkbox.length && checkbox.checked === false) {
        this.error.legal = true
        return false
      }

      // Si hay 0 elementos, no dejamos hacer pedido
      if (this.numItems && !this.enviando && this.optionId) {
        // console.log(`--- finalizar pedido ---`)

        this.items.map((item) => {
          /*
            id = 1 , Es un producto tipo Código 0. No puede estar en Pedidos
            id = 3960 ,  Es un producto  Tipo Recogida. No puede Estar en Ofertas.
          */
          let id = item.product_data.id
          // console.log(`item: `, id)
          if (id === 1 && this.optionId === 1 && error === false) {
            const msg = this.$gettext('No está permitido crear pedidos con líneas: <strong>Código 0</strong>')
            this.showModalMsg(msg)
            error = true
          } else if (id === 3960 && this.optionId === 2 && error === false) {
            const msg = this.$gettext('No está permitido crear ofertas con líneas: <strong>Recogida</strong>')
            this.showModalMsg(msg)
            error = true
          } else if (!item.is_valid_product && error === false) {
            // si el producto es obsoleto,  bloqueado o no es valido.
            const msg = this.$gettext('Algunos productos ya no están disponibles, por favor elimínalos antes de continuar con la compra.')
            this.showModalMsg(msg)
            error = true
          }
        })

        if (this.optionId === 1 && !error) {
          this.enviando = true
          // console.log(`Envio Pedido: ${this.optionId}`)
          if (this.isPunchout) {
            SrvCatalog.setPunchoutPreOrder(this.observations, this.nPedidoCliente, this.pickupDate.date)
              .then(res => {
                if (res.status === 200) {
                  this.punchoutPostUrl = res.data.form_post_url
                  this.punchoutPostXml = res.data.form_post_xml
                  setTimeout(() => {
                    this.$refs.punchoutSubmit.click()
                  }, 1500)
                } else {
                  this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
                  window.scrollTo(0, 0)
                }
                this.enviando = false
              }, (error) => {
                // console.log(`setOrder error: ${error.status}, error mensaje: ${error.data.message}, error response:`, error.data)
                if (error.data.message === undefined) {
                  this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
                } else if (error.data.code === '100') {
                  this.messageShow = true
                  this.messageError = 1 // this.$gettext('Este pedido supera el límite de consumo asignado a este centro. El pedido pasa a estar pendiente de validación.')
                  // borramos carrito
                  this.$store.dispatch('deleteCart')
                  // actualizamos el valor de los pedidos pendientes.
                  this.$store.dispatch('updateValidableOrders')
                } else {
                  this.messageError = error.data.message
                }
                window.scrollTo(0, 0)
                this.enviando = false
              })
          } else {
            SrvCatalog.setOrder(this.observations, this.nPedidoCliente, this.pickupDate.date)
              .then(res => {
                // console.log(`------> respuesta API finalización pedido: `, res)
                if (res.status === 200) {
                  this.messageShow = true // this.$gettext('Pedido realizado con exito.')
                  window.scrollTo(0, 0)
                  this.$store.dispatch('deleteCart')
                } else {
                  this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
                  window.scrollTo(0, 0)
                }
                this.enviando = false
              }, (error) => {
                // console.log(`setOrder error: ${error.status}, error mensaje: ${error.data.message}, error response:`, error.data)
                if (error.data.message === undefined) {
                  this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
                } else if (error.data.code === '100') {
                  this.messageShow = true
                  this.messageError = 1 // this.$gettext('Este pedido supera el límite de consumo asignado a este centro. El pedido pasa a estar pendiente de validación.')
                  // borramos carrito
                  this.$store.dispatch('deleteCart')
                  // actualizamos el valor de los pedidos pendientes.
                  this.$store.dispatch('updateValidableOrders')
                } else {
                  this.messageError = error.data.message
                }
                window.scrollTo(0, 0)
                this.enviando = false
              })
          }
        } else if (this.optionId === 2 && !error) {
          this.enviando = true
          // console.log(`Envio Oferta: ${this.optionId}`)
          SrvCatalog.setOffers(this.observations, this.printFormatsId)
            .then(res => {
              // console.log(`------> respuesta API finalización oferta: `, res)
              if (res.status === 200) {
                this.messageShow = true // this.$gettext('Oferta realizada con exito.')
                window.scrollTo(0, 0)
              } else {
                this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
                window.scrollTo(0, 0)
              }
              this.enviando = false
            }, (error) => {
              // console.log('----->', error)
              if (error.status === 104) {
                this.messageShow = true
                this.messageError = 1 // this.$gettext('Está oferta supera el límite de consumo asignado. La oferta pasa a estar pendiente de validación.')
                // borramos carrito
                this.$store.dispatch('deleteCart')
                // actualizamos el valor de las ofertas pendientes.
                this.$store.dispatch('updateValidableOffers')
              } else {
                this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
              }
              window.scrollTo(0, 0)
              this.enviando = false
            })
        } else if (this.config_app.catalog_generator && this.optionId === 3 && this.optionCatalogId && !error) {
          this.enviando = true
          let formData = new FormData()
          // Add the form data we need to submit
          formData.append('catalog_name', this.catalogName)
          formData.append('catalog_type', this.optionCatalogId)
          formData.append('catalog_items_per_page', this.optionCatalogItemPerPageId)
          formData.append('catalog_details', this.observations)
          if (this.customerLogo) {
            formData.append('catalog_client_logo', this.customerLogo)
          }
          // console.log(`Envio Oferta: ${this.optionId}`)
          SrvCatalog.setCatalogPDF(formData)
            .then(res => {
              // console.log(`------> respuesta API finalización oferta: `, res)
              if (res.status === 200) {
                this.messageShow = true // this.$gettext('Oferta realizada con exito.')
                this.$store.dispatch('deleteCart')
                window.scrollTo(0, 0)
              } else {
                this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
                window.scrollTo(0, 0)
              }
              this.enviando = false
            }, (error) => {
              // console.log('----->', error)
              if (error.status === 104) {
                this.messageShow = true
                this.messageError = 1 // this.$gettext('Está oferta supera el límite de consumo asignado. La oferta pasa a estar pendiente de validación.')
                // borramos carrito
                this.$store.dispatch('deleteCart')
                // actualizamos el valor de las ofertas pendientes.
                this.$store.dispatch('updateValidableOffers')
              } else {
                this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
              }
              window.scrollTo(0, 0)
              this.enviando = false
            })
        }
      }
    },
    getClient () {
      SrvClients.getClientById(this.client.id)
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Data Dashboard VentasRankingCenter -> Client: ', res.data)
            let client = res.data
            // Enviamos a Store Client los datos del cliente seleccionado
            this.$store.dispatch('updateClient', client)
            // Lo pasamos a su State
            this.$store.commit('UPDATE_CLIENT')
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    showModalMsg (msg) {
      const aceptar = this.$gettext('Aceptar')
      this.modal.content = `<p style="text-align:center;padding-top:20px;">${msg}</p>
                            <p style="text-align:center;padding:20px 0 10px;"><button type="button" data-dismiss="modal" class="btn btn-action">${aceptar}</button></p>`
      // En el HTML hay un botón con el atributo ref="showModal" y de esta forma le hacemos click para mostrar la modal.
      this.$refs.showModal.click()
    },
    changeSort () {
      // bloqueamos el arrastre.
      this.dragdrop.senData = true
      this.messageError = ''
      SrvCatalog.updateCart(this.items)
        .then(res => {
          // console.log('Carrito Actualizado..'')
          this.dragdrop.senData = false
          this.$store.dispatch('getCart')
        }, (error) => {
          // console.log(error.data.discount + '----->', error)
          if (error.status === 400 && error.data.discount !== undefined) {
            this.messageError = error.data.discount[0]
          } else if (error.status === 400) {
            this.messageError = error.data
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        })
    },
    changeCalendar (value) {
      let aDate = value.toString().split(',')
      console.log('date: ', aDate)
      /* if (aDate.length === 2) {
        this.calendar.after = this.$moment(aDate[0]).format('YYYY-MM-DD[T]HH:mm:ss')
        this.calendar.before = this.$moment(aDate[1]).endOf('month').format('YYYY-MM-DD[T]HH:mm:ss')
        // console.log(`${this.calendar.after}, ${this.calendar.before}`)
      } */
    },
    previewFiles (event) {
      let ext = event.target.files[0].name.split('.').pop()
      let size = event.target.files[0].size
      if (size < 2000000 && (ext === 'png' || ext === 'jpg')) {
        this.customerLogo = event.target.files[0]
        this.fileInputName = event.target.files[0].name
      } else {
        const msg = this.$gettext('Add logo in png or jpg format, max size 2Mb')
        this.showModalMsg(msg)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.row{
 &.fileInputName{
   margin-top:15px;
   margin-bottom:15px;
  .txt-c-blue {
      color: #00adfc;
  }
  input[type="file"]{
    padding: 8px 12px;
    height: 36px;
    line-height: normal;
    border: 1px solid #ccc;
    float:left;
  }
  input.showFileStatus{
    height: 44px;
    width: 80%;
    float:left;
    padding-left: 100px;
  }
  .w-20{
    width:20%;
    float:left;
  }
  .w-80{
    width:80%;
    float:left;
  }

 }
}


  #opcion-carrito-catalogo-pdf{
    border-top:1px solid rgb(194, 194, 194);
    border-bottom:1px solid rgb(194, 194, 194);

    margin: 15px 0;
    padding: 15px 0;
  }
  .cart-list{
    position: relative;
    padding-bottom: 200px;
  }

  .info-pedido-ok{
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #e6f7d4;
    z-index: 10;
    min-height: 700px;

    &.error{
      background-color: #FFF1D3;
    }
    & > div{
      padding: 100px 40px 40px;
    }

    p{
      text-align: center;
      font-size: 22px;
      color: #5c6783;
      font-weight: $light;
      margin-bottom: 30px;
    }

    i{
      color: #ace174;
      border: 3px solid #ace174;
      @include roundAll100(50%);
      width: 70px;
      height: 70px;
      line-height: 64px;
      font-size: 40px;

      &.orange-ico{
        color: #FEC731;
        border-color: #FEC731;
      }
    }

    a{
      display: inline-block;
      margin-left: 6px;
    }

    strong{
      color: #2b569b;
      font-size: 30px;
      font-weight: $light;
    }
  }

  .box-1{
    padding: 14px 0;
    background-color: #EFEFEF;
    margin-bottom: 40px;

    p{
      font-size: 14px;
      color: #43425D;
      margin:0;
    }

    .btn-action{
      margin-left: 20px;
      margin-right: 20px;
      font-size: 14px;
    }
  }

  .section-cn{
    .head-list{
      border-bottom: 2px solid #17296E;
      display: flex;
      align-items: center;

      & > *{
        width: 12%;
        padding: 0 10px 10px;
      }

      .big{
        width: 40%; // 48%;
        &.no-formato{
          width: 52%;
        }
      }
    }
  }

  .cargos-list{
    margin-top: 14px;
  }

  .box-2{
    padding-top: 28px;
    // margin-top: 30px;
    // border-top: 2px solid #17296E;
    padding-bottom: 28px;
    // border-bottom: 1px solid $orange;

    .info-centro{

      & > * {
        display: inline-block;
        vertical-align: top;
        &:first-child{
          width: 124px;
        }
        &:last-child{
          background-color: rgba(#F1F4F7, .38);
          padding: 18px;
          font-size: 14px;
          color: #4D4F5C;
          width: 320px;
          min-height: 100px;
        }
      }

      .btn{
        font-size: 14px;
        white-space: normal;
        text-align: left;
      }
    }

    ul{
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li{
      font-size: 16px;
      color: #5b5d5e;
      margin-bottom: 14px;

      small{
        font-size: 16px;
        float: right;
      }

      &.legend-shipping{
        color: $orange;
        font-size: 14px;
      }

      &:last-child{
        font-size: 16px;
        margin-bottom: 0;

        small{
          font-weight: $medium;
          font-size: 16px;
          color: #1C2C40;
        }
      }
    }
  }

  .box-3{
    padding:20px 0 40px;
    border-top: 2px solid #17296E;
    .mt-6{
      margin-top:6px;
    }
    .mt-10{
      margin-top:10px;
    }
    label{
      &.tr{
        font-weight: $regular;
      }
    }
    .obser-cn{
      label{
        font-weight: $regular;
        color: #494f58;
        font-size: 14px;
        vertical-align: top;
        margin-right: 10px;
        width: 130px;
        padding-left: 15px;
      }

      textarea{
        display: inline-block;
        width:320px;
        height: 100px;
        border: 1px solid #d5d8dc;
      }
    }

    .Select-bk{
      width: 158px!important;
      // border: 1px solid #d5d8dc;
      margin-bottom: 16px;

      button{
        width: 100%!important;
      }
    }

    button{
      margin-right: 16px;
      width: 126px;

      &:last-child{
        margin-right: 0;
      }
    }

    .btn-action{
      font-size: 16px;
      font-weight: $regular;
      background-color: $orange;
      text-transform: none;

      &:hover, &:focus{
        background-color: darken($orange, 10%);
      }
    }

    .btn-cancel{
      font-size: 16px;
      font-weight: $regular;
    }

    .submit-cn{
      margin-top: 0;
    }

  }

  .box-3{
    &.type-vario{
      .row{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        label{
          display: inline-block;
          font-size: 14px;
        }

        input, textarea{
          display: inline-block;
          width: 80%;
        }

        textarea{
          vertical-align: text-top;
        }

        input[type="file"]{
          padding: 8px 12px;
          height: 36px;
          line-height: normal;
          border: 1px solid #ccc;
        }
      }

      .row-flex{
        border: 1px solid #707070;
        justify-content: flex-end;
        padding: 26px 14px;
        margin-top: 24px;

        p{
          margin:0 0 0 26px;
        }

        input[type="checkbox"]{
          margin-right: 10px;
        }
      }

      .c-l{
        label{
          width: 20%;
        }

        & > *{
          margin:0;
          &:not(:last-child){
            margin-bottom: 15px;
          }
        }
      }

      .c-r{
        text-align: right;
        & > div > div{
          &:not(:last-child){
            margin-bottom: 15px;
          }
        }

        label{
          color: rgba(#1C2C40, .59);
          font-weight: $regular;
        }
      }

      .dropdown{
        width: 200px;
        margin-bottom: 0;
        button{
          width: 100%;
          margin-right: 0;
        }
      }

      .error-legal{
        color: $color-error;

        a{
          color: $color-error;
          text-decoration: underline;
        }
      }
    }
  }

  .aside-box{
    margin-bottom: 30px;

    h4{
      font-size: 14px;
      font-weight: $medium;
      color:#1C2C40;
      border-bottom: 2px solid #17296E;
      padding: 4px 0 10px;
      margin:0 0 10px;
    }

    h5{
      font-size: 14px;

      & > *{
        display: inline-block;
        vertical-align: middle;
      }

      button{
        font-size: 14px;
        margin-left: 14px;
      }
    }

    ul{
      margin:0;
      padding:18px;
      list-style: none;
      background-color: rgba(#F1F4F7, .38);
    }

    li{
      font-size: 14px;
      font-weight: $regular;
      color: #5b5d5e;
      margin-bottom: 10px;

      &:last-child{
        small{
          font-size: 16px;
          font-weight: $medium;
        }
      }

      small{
        font-size: 14px;
        float:right;
      }
    }

    p{
      padding: 18px;
      font-size: 14px;
      font-weight: $regular;
      color: #4D4F5C;
      margin-bottom: 18px;
      background-color: rgba(#F1F4F7, .38);
    }
  }

  .dropdown{
    display:inline-block;
    margin-bottom: 8px;
    width: 122px;

    li, button{
      color: #494f58;
      font-size: 14px;
    }

    .dropdown-toggle{
      height: 38px;
      line-height: 38px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 50px;

      i{
        width:40px;
        line-height: 36px;
        background-color: transparent;
        color: $blue-light;
      }
    }
  }

  .box-shipping{
    .row{
      padding: 0 0 30px;
      &.top-row{
        display: flex;
        align-items: center;
        padding: 30px 0 0;
        border-top: 2px solid #17296E;

        & > * {
          &:first-child{
            font-size: 16px;
            padding: 0;
            img{
              margin-right: 18px;
            }
          }
        }
      }

      & > * {
        color: #1C2C40;
        &:first-child{
          padding-left:0;
        }
        &:last-child{
          padding-right: 0;
        }
      }
    }

    .volume-cn{
      margin: 0;
      // text-align: center;
      font-size: 14px;
    }

    .shipping-free{
      background-color: #FAFBFC;
      p{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 58px;
        font-size: 16px;

        img{
          margin-right: 18px;
        }
      }
    }

    .shipping-Select{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 28px 0 30px;

      .dropdown{
        margin: 0;
        width: 270px;
      }

      p{
        font-size: 14px;
        margin: 0;

        &:last-child{
          font-size: 16px;
          font-weight: $medium;
          background-color: #E2E2E2;
          min-width: 70px;
          height: 38px;
          line-height: 38px;
          text-align: right;
          @include roundAll(2);
          padding: 0 8px;
          color: #646464;
        }
      }
    }

    .delivery-date{
      background-color: #EFEFEF;
      display: flex;
      align-items: center;
      // justify-content: center;
      height: 50px;
      font-size: 14px;
      padding: 0 18px;

      label{
        font-weight: $regular;
      }
    }

    .btn-trans{
      font-size: 14px;
    }
  }

  .box-payment{
    .dropdown{
      margin:0;
    }

    .row{
      padding: 30px 0;
      border-top: 2px solid #17296E;
      border-bottom: 2px solid #17296E;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > * {
        font-size: 14px;
        color: #1C2C40;

        &:first-child{
          padding-left:0;
          font-size: 16px;

          img{
            margin-right: 18px;
          }
        }

        &:last-child{
          text-align: right;
          padding-right: 0;

          em{
            background-color: #E2E2E2;
            min-width: 70px;
            height: 38px;
            line-height: 38px;
            text-align: right;
            display: inline-block;
            @include roundAll(2);
            padding: 0 8px;
            vertical-align: middle;
            font-size: 16px;
            font-weight: $medium;
          }
        }

        strong{
          font-weight: $regular;
          margin-right: 20px;
        }

        em{
          font-style: normal;
          color: #646464;
        }
      }
    }
  }
.nPedidoCliente{
    background-image: none;
    border: 1px solid #ccc;
        font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    height: 34px;
    padding: 6px 12px;
    margin-right: 15px;
}

#modal-others-products{
  padding-right:0 !important;
}
</style>
