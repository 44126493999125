<template lang="pug">
  .ofertas-list-item
    .container
      .row.box-1
        .col-sm-4
            span.img-cn(v-if="isTypeApp === 'vario'" @click="goSingle()")
              img(:src="img.derecha" width="8")
            span.img-cn(v-else data-toggle="collapse" :data-target="'#oferta-'+index")
              img(:src="img.derecha" width="8")
            p.name(@click="goSingle()") {{nameFront}}
        .col-sm-2(@click="goSingle()")
          p.text-right {{item.number}}
        .col-sm-2(@click="goSingle()")
          p.text-center {{item.offer_datetime | moment("DD-MM-YYYY")}}
        .col-sm-2(@click="goSingle()")
          p.text-right {{item.total | format-money}} {{item.currency_symbol}}
        .col-sm-2(@click="goSingle()")
          p.tw-flex.tw-justify-center
            img(:src="img.ver" width="18")

      .row.box-2(:id="'oferta-'+index" class="collapse" v-if="center")
        .col-sm-6.col-md-3.c-1
          p #[translate Centro]: #[strong {{checkEmpty(center.address)}}]
          p #[translate Código de Centro]: #[strong {{checkEmpty(center.external_code)}}]

</template>
<script>
export default {
  name: 'OfertasListItem',
  props: ['item', 'index'],
  data () {
    return {
      img: {
        derecha: require('@/assets/img/icos/derecha.svg'),
        ver: require('@/assets/img/icos/ver-oferta.svg')
      }
    }
  },
  created () {
    // console.log(this.item)
  },
  computed: {
    center () {
      // console.log(this.item.center)
      return this.item.center
    },
    client () {
      return this.item.client
    },
    lead () {
      return this.item.lead
    },
    nameFront () {
      return (this.lead) ? this.lead.name : this.client.name
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  methods: {
    checkEmpty (v) {
      return (v === null || v === '') ? '-' : v
    },
    goSingle () {
      // console.log(`goSingle Ofeta: `, this.item.id)
      this.$router.push({name: 'ofertaSingle', params: {id: this.item.id}})
    }
  }
}
</script>
<style lang="scss" scoped>
  .ofertas-list-item{
    color:#494f58;
  }

  .box-1{
    @include transition();
    font-size: 14px;
    color: #4D4F5C;
    font-weight: $regular;
    border:1px solid #fff;
    cursor: pointer;

    & > *{
      padding-top:12px;
      padding-bottom:8px;
    }

    &:hover, &:focus{
      background-color: rgba(#129FE9, .2);
      border:1px solid #00ADFC;

      .name{
        font-weight: $medium;
      }
    }

    p{
      margin:0;

      &.name{
        text-transform: uppercase;
        margin-left: 34px;
        @include ellipsis();
        cursor: pointer;
        @include transition();
      }
    }
  }

  .img-cn{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    height: 22px;
    margin: auto;
    padding-right: 40px;
    img{
      @include transition();
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  [aria-expanded="true"]{
      img{
        -moz-transform: rotate(90deg) translate(0px);
        -o-transform: rotate(90deg) translate(0px);
        -ms-transform: rotate(90deg) translate(0px);
        -webkit-transform: rotate(90deg) translate(0px);
        transform: rotate(90deg) translate(0px);
      }
  }

  .box-2{
    padding: 16px 0 46px 34px;
    background-color: #fff;
    border-bottom: 1px solid #F5F6FA;
    color: #4D4F5C;

    strong{
      font-weight: $regular;
    }
  }


</style>
