<template lang="pug">
  div( :id="idModal" class="modal fade" role="dialog")
    .modal-dialog
      .modal-content
        .modal-header(style="padding: 1rem 2rem")
          p
            div
              strong #[translate ¡Qué no se te olvide nada!] &nbsp;
            div
              span #[translate En otras ocasiones has comprado también estos productos]
          button(@click="next()" type="button" class="close")
            img(:src="img.close" width="25")
          button.hidden(@click="next()" type="button" class="close" data-dismiss="modal" ref="close")
            img(:src="img.close" width="25")

        .modal-body
          div
            .product-list
              bk-item(v-for="product in aProduct" :key="product.id" :product="product" :view="viewGrid" @toAddCart="toAddCart" @toGoProduct="toGoProduct" :modalOthersProducts="true")
        .modal-footer
          button(@click="next()") #[translate Continuar]
    span(class="hidden") #[translate xxx#/]
</template>
<script>
import * as types from '@/store/mutation-types'
import BkItem from '@/components/catalog/Item-OtherProducts.vue'
export default {
  name: 'ModalImage',
  props: ['id', 'aProduct'],
  components: {BkItem},
  data () {
    return {
      viewGrid: 'grid',
      img: {
        close: require('@/assets/img/icos/x_cerrar.svg')
        // head: require('@/assets/img/head-modal-others-products.png')
      },
      migas: [],
      countOthersProductsAdds: 0
    }
  },
  computed: {
    idModal () {
      if (this.id) {
        return this.id
      }
      return 'modal-others-products'
    }
  },
  created () {
  },
  methods: {
    next () {
      this.$refs.close.click()
      this.$store.commit(types.MODAL_OTHERS_PRODUCTS_VIEW, true)
      if (this.countOthersProductsAdds === 0) {
        // EMIT FINALIZAR PEDIDO
        this.$emit('endOrder')
      }
    },
    generateMigasSlug () {
      let aMigasSlug = ''
      this.migas.map((item) => {
        // console.log(`item migas: ${item.id}`)
        aMigasSlug += (aMigasSlug === '') ? item.id : '-' + item.id
      })
      // console.log('---------aMigasSlug: ', aMigasSlug)
      return aMigasSlug
    },
    toAddCart (item) {
      // console.log(`Component page/CatalogoList -> id product: ${item.idProduct}, quantity: ${item.quantity} , item: `, item.product)
      // Envia al carrito
      this.countOthersProductsAdds++
      this.$store.dispatch('addCart', item)
      let elemento = document.querySelector('[data-id="' + item.idProduct + '"]')
      elemento.style.opacity = '0.4'
    },
    toGoProduct (id) {
      // console.log(`-----> toGoProduct: ${id}, migas: `, this.migas)
      this.$refs.close.click()
      this.idProduct = id
      const aMigasSlug = this.generateMigasSlug()
      // console.log('---------aMigasSlug: ', aMigasSlug)
      // Enviamos a la store  el slug de las migas.
      this.$store.dispatch('setCatalogueMigasSlug', aMigasSlug)
      this.$router.push({'name': 'productSingle', 'params': {'id': id.toString(), 'migas': this.migas}})
    }
  }
}
</script>
<style lang="scss" scoped>
.modal{
  max-width:1080px;
  margin: 0 auto;
}
.modal-dialog{
  max-width: 100%;
  width: 1080px;
}
.modal-content{
  border: none;
  @include roundAll(0);
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
  box-shadow: 0 3px 6px rgba(0,0,0,.25);
  overflow: hidden;
  .catalog-GridItem{
    float: left;
  }
}
.modal-header{
  padding:0;
  background-color:#EAEDED;
  border:0;
  .text-center{
    text-align: center;
  }
  .dibujo{
    position:relative;
  }
  p{
    display: inline-block;
    font-size: 14px;
  }
  .close{
    margin-top: 0;
    right: 10px;
    font-size: 40px;
    opacity: 1;
    position: absolute;
    top: 0;
  }
}

.modal-body{
  max-width: 100%;
  padding: 0;
  font-size: 16px;
  font-family: "Roboto", Arial, Verdana,serif;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;

  .catalog-GridItem{
    border: 1px solid #EAEDED;
    margin-bottom: 0;
    width: 100%;

    .img-cn{
      float:left;
      width: 40%;
    }
    .text-center{
      float:right;
      width: 60%;
    }
    .code-cn{
      float:right;
      width: 60%;
    }
  }
}

.modal-footer{
  background-color:#EAEDED;
  text-align: center;
  button{
    background-color: #FF8054;
    color:#fff;
    padding:10px 30px;
    border:none;
  }
}

h2{
  font-size: 18px;
  margin: 0;
}

.product-list {
  display: grid;
  gap: 0;
  grid-template-columns: repeat(4, 1fr);
}
</style>
