<template lang="pug">
  #catalog-list
      .box-1(:class="{'type-pedrosa': isTypeApp === 'pedrosa'}")
        .container
          .row.row-search
            .col-sm-7
              bk-search(@toSearchCategory="toSearchCategory" :categories="aCat" :category="catActive" type="catalogoPublic")

            .col-sm-5
              bk-banner-search(@toSetCategory="setCategory")

      bk-banner-slider(@toSetCategory="setCategory" :catActive="catActive" :query="query")

      // Mostrar listado
      .box-2(v-show="$route.name !== 'productSinglePublic'")
        .container
          .row
            .col-sm-3.sidebar-cn
              bk-filter-sidebar(@toFilterCategory="toFilterCategory" @toGetMigas="toGetMigas" @toGetCat="toGetCat" :query="query" :category="filterCategory" :typeProduct="typeProduct" :filterByType="filterByType")
              img(v-if="appConfig.featureFlags.infoSoste" :src="img.bannerSoste" class="tw-w-[250px] tw-m-auto tw-cursor-pointer" @click="$store.commit('MODAL_INFO_SOSTE_VIEW', true)")
            .holder-catalogo.col-sm-9

                bk-migas(v-if="migas.length > 1000" :migas="migas" :showHidenDetails="idProduct" @toHidenDetails='hidenDetails' @toFilterCategory="toFilterCategory")
                .filter-grid
                  bk-filter-top(@toChangeView="toChangeView" @toChangeFilterProduct="toChangeFilterProduct" @toChangeFilterByType="toChangeFilterByType" @toChangeOrdering="toChangeOrdering" :view="viewGrid")
                  bk-cat-featured(v-if="catActive && catActive.level === 1 && query === ''" :catActive="catActive")
                  bk-grid(:view="viewGrid" :category="catActive" :query="query" :ordering="ordering" :typeProduct="typeProduct" :filterByType="filterByType" @toGoProduct="toGoProduct" @toFilterCategory="toFilterCategory")

      // Mostramos el detalle del producto.
      div(v-if="$route.name === 'productSinglePublic'")
        transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
          router-view
</template>
<script>
import {mapGetters} from 'vuex'

import BkSearch from '@/components/catalog/Search.vue'
import BkMigas from '@/components/catalog/Migas-list.vue'
import BkFilterSidebar from '@/components/catalog/Filter-sidebar.vue'
import BkFilterTop from '@/components/catalog/Filter-top.vue'
import BkGrid from '@/components/catalog/Grid-public.vue'
import BkCatFeatured from '@/components/catalog/category-featured.vue'
import BkBannerSearch from '@/components/catalog/banner/search.vue'
import BkBannerSlider from '@/components/catalog/banner/slider.vue'
import appConfig from '@/config-app.js'

export default {
  name: 'CatalogoListPublic',
  components: { BkSearch, BkMigas, BkFilterTop, BkGrid, BkFilterSidebar, BkCatFeatured, BkBannerSearch, BkBannerSlider },
  props: ['catProductSingle'],
  data () {
    return {
      appConfig: appConfig,
      viewGrid: 'grid',
      filterCategory: '',
      query: '',
      aCat: [], // Guardamos todas las categorías.
      catActive: null, // guardamos la categoría activa.
      idProduct: 0, // Cuando asignemos un ID se vera el detalle del producto con ese ID.
      migas: [],
      ordering: '', // Ordenación de producto
      typeProduct: '', // Si es autorizado o no.
      filterByType: '', // si es mas consumido, outlet y eco.
      img: {
        bannerSoste: require('@/assets/img/banner_orbita_IS.svg')
      }
    }
  },
  computed: {
    ...mapGetters([
      'role'
    ]),
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  created () {
    if (!this.config_app.catalogo_public) {
      window.location = '/'
    }
  },
  mounted () {
    // console.log('mounted Catálogo')
    // this.migas.push(this.migasIni)
  },
  methods: {
    toChangeView (type) {
      // console.log(`---- ${type}`)
      this.viewGrid = type
    },
    toFilterCategory (item) {
      // console.log('toFilterCategory:', item)
      // this.query = '' // Para resetear la busqueda.
      this.filterCategory = (item) ? item.id : ''
      this.catActive = item || null
      this.idProduct = 0 // para quitar el detalle del producto si esta activo
    },
    toGetMigas (v) {
      // console.log('toGetMigas: ', v[0])
      this.migas = v[0]
      this.idProduct = 0 // para quitar el detalle del producto si esta activo
    },
    toGetCat (aCat) {
      // console.log('aCat:', aCat)
      this.aCat = aCat
    },
    toSearchCategory (aDatos) {
      // console.log(`--query: ${aDatos[0]}, idCat: ${aDatos[1]}`)
      if (this.$route.name === 'productSinglePublic' && aDatos[0]) {
        // si estamos en el detalle de un producto, nos vamos al listado.
        this.$router.push({'name': 'catalogoListPublic'})
      } else {
        // si estamos en el listado cerramos todas las cateogías
        // window.$(`#catalog-menu button`).removeClass('open')
        // window.$(`#catalog-menu ul`).removeClass('in')
      }

      this.query = aDatos[0]
      this.filterCategory = aDatos[1] // asignar categoría, si viene categoría del buscador.
      this.idProduct = 0 // para quitar el detalle del producto si esta activo
    },
    toGoProduct (id) {
      // console.log(`-----> toGoProduct: ${id}, migas: `, this.migas)
      this.idProduct = id
      const aMigasSlug = this.generateMigasSlug()
      // console.log('---------aMigasSlug: ', aMigasSlug)
      // Enviamos a la store  el slug de las migas.
      this.$store.dispatch('setCatalogueMigasSlug', aMigasSlug)
      this.$router.push({'name': 'productSinglePublic', 'params': {'id': id.toString(), 'migas': this.migas}})
    },
    hidenDetails () {
      // console.log(`Ocultar detalle producto`)
      this.idProduct = 0
    },
    toChangeFilterProduct (v) {
      // console.log(`toChangeFilterProduct: ${v}`)
      this.typeProduct = v
    },
    toChangeFilterByType (v) {
      // console.log(`toChangeFilterProduct: ${v}`)
      this.filterByType = v
    },
    toChangeOrdering (v) {
      // console.log(`changeOrderProduct: ${v}`)
      this.ordering = v
    },
    setCategory (v) {
      // console.log('setCategory:', v)
      const aMigas = v.split('-')
      if (this.$route.name === 'catalogoListPublic') {
        aMigas.map((item, index) => {
          window.$(`#catalog-menu button[data-id="${item}"]`).click()
        })
      } else {
        // Enviamos a la store  el slug de las migas.
        this.$store.dispatch('setCatalogueMigasSlug', v)
        // Y nos vamos al listado del catálogo.
        this.$router.push({'name': 'catalogoListPublic'})
      }
    },
    generateMigasSlug () {
      let aMigasSlug = ''
      this.migas.map((item) => {
        // console.log(`item migas: ${item.id}`)
        aMigasSlug += (aMigasSlug === '') ? item.id : '-' + item.id
      })
      // console.log('---------aMigasSlug: ', aMigasSlug)
      return aMigasSlug
    }
  },
  watch: {
    // Viene de la página de productosSingle cuando pulsas en las migas.
    catProductSingle () {
      // console.log(`catProductSingle: ${this.catProductSingle}`)
      // const value = (this.catProductSingle) ? this.catProductSingle : ''
      // this.toFilterCategory(value)
    },
    '$route' (to, from) {
      // react to route changes...
      if (to.name === 'catalogoListPublic') {
        // Si volvemos al catálogo desde el producto. Ponemos el id de producto a 0 para que se vean las migas.
        this.idProduct = 0
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  #catalog-list{
    position:relative;
  }
  .filter-grid{
    background-color: #FAFAFA;
    padding: 12px 17px 0;
  }

  .box-1{
    background-color: #EFEFEF;
    padding:14px 0;
    margin-top: 60px;
    &.type-pedrosa{
      margin-top: 0;
    }
  }

  .row-search{
    display: flex;
    align-items: center;

    .banner{
      text-align: right;

      a{
        display: inline-block;
        &:not(:last-child){
          margin-right: 24px;
        }
      }
    }
  }

  .box-slider{
    // margin-top: 16px;
    background-color: rgba(#EFEFEF, .35);
    overflow: hidden;
    @include transition(.6);
    height: 200px;

    &.noShow{
      height: 0;
      // opacity: 0;
    }

    .carousel-control{
      width: 60px;
      height: 60px;
      line-height: 60px;
      margin: auto;
      @include roundAll100(50%);
      background-color: rgba(#000, .3);

      &.left{
        left: 30px;
      }

      &.right{
        right: 30px;
        line-height: 54px;
        transform: rotateZ(180deg);
      }
    }
  }

  .box-2{
    .sidebar-cn{
      padding-right: 0;
    }

    .holder-catalogo{
      padding-left:0;
    }
  }
</style>
