<template lang="pug">
  #modal-oferta(class="modal fade" role="dialog")
    .modal-dialog.modal-lg
      .modal-content
        .modal-header
          button(type="button" class="close" data-dismiss="modal")
            img(:src="img.close" width="12")
          bk-search(
            :type="'modal'"
            :count="itemNum"
            :typeSearch = "typeSearch"
            @toSearch="toSearch")
        .modal-body
          bk-grid(
            :centers="aData"
            :message="message"
            :pages="totalPages"
            :count="itemNum"
            :currentPage="currentPage"
            :typeSearch="typeSearch"
            @toPaginate="pagChange")
</template>
<script>
import BkSearch from '@/components/ofertas/Modal-search.vue'
import BkGrid from '@/components/ofertas/Modal-listado.vue'

// importamos servicios
import SrvClients from '@/services/clients'
import SrvLead from '@/services/lead'

export default {
  name: 'OfertasModal',
  components: { BkSearch, BkGrid },
  props: ['classOpen'], // clase para abrir el popup
  data: () => ({
    message: null,
    aData: [],
    itemNum: null,
    totalPages: 0,
    init: false, // Para comprobar si es la primera vez que llamamos a la API
    currentPage: 0, // Para saber porque página vamos en la paginación
    query: '', // Donde se guarla la palabra a buscar.
    typeSearch: null, // buscar clientes = 1,3 ó lead = 2
    img: {
      close: require('@/assets/img/icos/cerrar-01.svg')
    }
  }),
  created () {
  },
  methods: {
    pagChange (pageNum) {
      // console.log(`query ${this.query}, pageNum: ${pageNum}`)
      this.search(pageNum)
    },
    toSearch (query) {
      this.query = query
      this.search(1)
    },
    search (page) {
      // console.log(`---> Search modal: query: ${this.query}, page: ${page}, typeSearch: ${this.typeSearch}`)
      if (this.typeSearch === 2) {
        // Es lead
        this.searchLead(page)
      } else {
        // es cliente
        this.searchClient(page)
      }
    },
    searchClient (page) {
      SrvClients.getClients(this.query, page)
        .then(res => {
          // console.log(`---> Respuesta servicio Search center modal: `, res)
          if (res.status === 200) {
            // Añadimoslos centros al listado de la modal.
            this.aData = res.data.results
            this.itemNum = res.data.count
            this.totalPages = res.data.total_pages
            this.currentPage = page
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    searchLead (page) {
      SrvLead.getLeads(this.query, page)
        .then(res => {
          // console.log(`---> Respuesta servicio Search center modal: `, res)
          if (res.status === 200) {
            // Añadimoslos centros al listado de la modal.
            this.aData = res.data.results
            this.itemNum = res.data.count
            this.totalPages = res.data.total_pages
            this.currentPage = page
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    openModal (typeCartId) {
      // console.log(`type cart ID modal: ${typeCartId}`)
      this.aData = []
      this.typeSearch = typeCartId
      this.query = ''
      this.search(1)
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal-content{
    border: 1px solid #17296E;
    @include roundAll(5);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
    box-shadow: 0 3px 6px rgba(0,0,0,.25);
    overflow: hidden;
  }
  .modal-header{
    background-color: #EBF3FE;
    padding-top:22px;
    padding-bottom: 22px;

    .close{
      margin-top: -22px;
      font-size: 40px;
      opacity: 1;
    }
  }

  .modal-body{
    padding-left:0;
    padding-right: 0;
  }
</style>
