<template lang="pug">
  #catalog-product(v-if="product")
    .container.box-1
      .row
        .col-sm-5.text-center
          .holder-img( :class="{'type-outlet': product.is_outlet, 'type-eco': product.is_eco}" )
            small(v-if="product.is_eco") #[translate Eco]
            small(v-if="product.is_outlet") #[translate Outlet]
            img(:src="checkEmptyImg(productImg)")
            tag-soste(v-if="appConfig.featureFlags.infoSoste" :hover="false" :index="product.sustainability_index" :productId="product.id")
            bk-favorite(class="favorite-img" :data-id="product.id")
            i.fa.fa-search
        .col-sm-7
          h3.title {{checkEmpty(product.name)}}
          .descripction-cn(v-if="description") {{description}}

          .row
            .info-cn(:class="{'col-sm-7': isLoggedIn, 'col-sm-12': !isLoggedIn}")
              ul
                // li(v-if="product.supplier") #[translate Proveedor]:
                  strong {{product.supplier.name}}
                li(v-if="type_app === 'pedrosa'") #[translate Ref.]
                  strong {{product.external_id}}
                li(v-else) #[translate Código]:
                  strong {{product.external_id}}

              p.doc-cn
                a(:href="product.doc_technical_url" target="_blank" class="doc-link" v-if="product.doc_technical_url")
                  img(:src="img.docDownload" width="13" alt="")
                  span #[translate Ficha Técnica]
                a(:href="product.doc_security_url" target="_blank" class="doc-link" v-if="product.doc_security_url")
                  img(:src="img.docDownload" width="13" alt="")
                  span #[translate Ficha Seguridad]

              .consumo-cn(v-if="center")
                p.consumo-data
                  span(v-for="(item, index) in product.center_2y_consumptions" v-if="index > 11")
                    strong {{meses[item.month-1]}}
                    small {{checkEmpty(item.value)}}
            .col-sm-5.c-r(v-if="isLoggedIn")
              div.info-addCart(v-show="product.price !== null")
                p.pvp #[translate Precio €/unidad:] #[strong {{price | format-money}} {{product.currency_symbol}} ]
                div.und(:class="{'noStock': !product.stock, 'normalStock': config_app.stock_availability}")
                  p(v-if="config_app.stock_availability") to be delisted XXXX
                  p(v-else-if="!config_app.stock_availability && config_app.stock && !product.is_network") {{product.stock}} #[translate en stock]
                  p(v-else) &nbsp;
                form(@submit.prevent="toAddCart()")
                  .formato(v-if="config_app.formato && aFormato && aFormato.length > 0")
                    label #[translate Formato]
                    .dropdown.Select-bk
                      button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddFormato" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{formatoItem.name}} ({{formatoItem.quantity}})
                        i(class="fa fa-angle-down" aria-hidden="true")
                      ul(class="dropdown-menu" aria-labelledby="ddFormato")
                        li(v-for="item in aFormato" :class="{'disabled': formatoItem.id == item.id}")
                          span(:value="item.id" @click="changeFormato(item)") {{item.name}}
                            span(v-if="item.id") &nbsp;#[translate de] {{item.quantity}} #[translate uds].

                  .cant
                    label #[translate Cantidad]
                    input(type="number" placeholder="0" min="0" :max="limitQuantity" v-model="quantity" @keyup="checkQuanty")
                  p.pvpTotal
                    span(v-if="total" :class="{'special':specialPriceType, 'no-stock': !product.stock, 'obsolete': product.obsolete}" ) {{total | format-money}} {{product.currency_symbol}}
                    span(v-else) &nbsp;
                  p.submit(v-if="!hiddenAddCart && (isClient || isLead)")
                    button(type="button" class="btn btn-orange" @click="toAddCart()") #[translate Añadir al Pedido]

              bk-cotiza(v-show="product.price === null" :product="product" type="single")

    .container.especificaciones-cn
      .row
        .col-sm-12(v-if="Object.keys(product.extra_info).length")
          h3 #[translate Especificaciones]
          ul.row-flex
            li
              p
                span Aplicaciones:
                strong {{ aplicaciones }}
            li(v-for="(key, index) in Object.keys(extraInfo)")
              p
                span {{ key }}:
                strong {{ extraInfo[key] }}
          
    .container.ecoCertificaciones
      .row
        .col-sm-12(v-if="ecoCertificaciones.length > 0")
          h3 #[translate Eco Certificaciones]
          ul.row-flex
            li(v-for="(item, index) in ecoCertificaciones")
              img(:src="item.image")
              p
                stron {{ item.value }}
          
        .col-sm-12(v-if="empleo_info")
          h3 #[translate Modo de Empleo]
          div {{empleo_info}}
        .col-sm-12(v-if="dosificacion_info")
          h3 #[translate Dosificación]
          div {{dosificacion_info}}

        .col-sm-12(v-if="aSeguridad.length")
          h3 #[translate Símbolos de seguridad]
          .item-ico(v-for="(item) in aSeguridad ")
            img.tw-inline-flex(:src="item.image_url" width="40")
            span &nbsp;&nbsp;&nbsp; {{item.name}}

        .col-sm-12(v-if="aObligacion.length")
          h3 #[translate Señales de Obligación]
          .item-ico(v-for="(item) in aObligacion ")
            img.tw-inline-flex(:src="item.image_url" width="40")
            span &nbsp;&nbsp;&nbsp; {{item.name}}

    .container.box-3(v-if="product.related_products.length")
      .row
        .col-sm-12
          h2 #[translate También te puede interesar]
      .row
        .col-sm-12.list-related-product
          div.item-cn( v-for="item in product.related_products")
            bk-item(:product="item" view="grid" @toAddCart="toAddCart" @toGoProduct="toGoProduct")
    div(v-if="appConfig.featureFlags.infoSoste" id="infoSoste")
      .container.box-3(v-if="product.more_eco_alternatives && product.more_eco_alternatives.length" class="tw-bg-[#E3F2E8]")
        .row
          div(class="tw-flex tw-text-left")
            svg(id="Componente_58_1" data-name="Componente 58 – 1" xmlns="http://www.w3.org/2000/svg" width="21.883" height="13.938" viewBox="0 0 21.883 13.938")
              path(id="Trazado_1247" data-name="Trazado 1247" d="M14.832,19.557a1.088,1.088,0,0,1-1.077-1.1V11.651c0-.392-.15-3.838-4.309-3.838H6.371c.267,1.645,1.174,4.524,4.242,4.524h1.144a1.1,1.1,0,0,1,0,2.193H10.613c-5.067,0-6.472-5.1-6.509-7.8a1.108,1.108,0,0,1,.31-.785,1.072,1.072,0,0,1,.767-.327H9.445a6.35,6.35,0,0,1,4.736,1.768,6.577,6.577,0,0,1,1.728,4.269v6.8A1.087,1.087,0,0,1,14.832,19.557Z" transform="translate(-4.104 -5.619)" fill="#fff")
              path(id="Trazado_1248" data-name="Trazado 1248" d="M23.926,11.625H17.91a1.1,1.1,0,0,1,0-2.193h6.016a1.1,1.1,0,0,1,0,2.193Z" transform="translate(-3.12 -5.25)" fill="#fff")
              path(id="Trazado_1249" data-name="Trazado 1249" d="M22.354,15.062H17.91a1.1,1.1,0,0,1,0-2.193h4.444a1.1,1.1,0,0,1,0,2.193Z" transform="translate(-3.12 -4.918)" fill="#fff")
              path(id="Trazado_1250" data-name="Trazado 1250" d="M20.514,18.5h-2.6a1.1,1.1,0,0,1,0-2.193h2.6a1.1,1.1,0,0,1,0,2.193Z" transform="translate(-3.12 -4.585)" fill="#fff")
            h2 #[translate Productos alternativos más sostenibles]
        .row
          .col-sm-12.list-related-product( class="!tw-bg-[#E3F2E8]")
            div.item-cn( v-for="item in product.more_eco_alternatives")
              bk-item(:product="item" view="grid" @toAddCart="toAddCart" @toGoProduct="toGoProduct")
      .container.box-3(v-else class="tw-bg-[#E3F2E8]")
        .row
          div(class="tw-flex tw-text-left tw-p-[20px]")
            svg(id="Componente_58_1" data-name="Componente 58 – 1" xmlns="http://www.w3.org/2000/svg" width="21.883" height="13.938" viewBox="0 0 21.883 13.938")
              path(id="Trazado_1247" data-name="Trazado 1247" d="M14.832,19.557a1.088,1.088,0,0,1-1.077-1.1V11.651c0-.392-.15-3.838-4.309-3.838H6.371c.267,1.645,1.174,4.524,4.242,4.524h1.144a1.1,1.1,0,0,1,0,2.193H10.613c-5.067,0-6.472-5.1-6.509-7.8a1.108,1.108,0,0,1,.31-.785,1.072,1.072,0,0,1,.767-.327H9.445a6.35,6.35,0,0,1,4.736,1.768,6.577,6.577,0,0,1,1.728,4.269v6.8A1.087,1.087,0,0,1,14.832,19.557Z" transform="translate(-4.104 -5.619)" fill="#fff")
              path(id="Trazado_1248" data-name="Trazado 1248" d="M23.926,11.625H17.91a1.1,1.1,0,0,1,0-2.193h6.016a1.1,1.1,0,0,1,0,2.193Z" transform="translate(-3.12 -5.25)" fill="#fff")
              path(id="Trazado_1249" data-name="Trazado 1249" d="M22.354,15.062H17.91a1.1,1.1,0,0,1,0-2.193h4.444a1.1,1.1,0,0,1,0,2.193Z" transform="translate(-3.12 -4.918)" fill="#fff")
              path(id="Trazado_1250" data-name="Trazado 1250" d="M20.514,18.5h-2.6a1.1,1.1,0,0,1,0-2.193h2.6a1.1,1.1,0,0,1,0,2.193Z" transform="translate(-3.12 -4.585)" fill="#fff")
            h2(class="!tw-ml-2") #[translate No existen productos alternativos más sostenibles]

</template>
<script>
import {mapGetters} from 'vuex'
import TagSoste from '@/components/catalog/Tag-Soste.vue'
import BkItem from '@/components/catalog/Item.vue'
import BkFavorite from '@/components/common/favorite.vue'
import BkCotiza from '@/components/common/Cotiza.vue'
import appConfig from '@/config-app.js'

// importamos servicios Catálogo
import SrvCatalog from '@/services/catalog'

export default {
  name: 'CatalogProduct',
  components: { BkItem, BkFavorite, BkCotiza, TagSoste },
  props: ['id'],
  data () {
    return {
      appConfig: appConfig,
      'ico1': require('@/assets/img/ficha/ico-especificaciones.png'),
      'ico2': require('@/assets/img/ficha/ico-dosificacion.png'),
      'ico3': require('@/assets/img/ficha/ico-empleo.png'),
      'ico4': require('@/assets/img/ficha/ico-seguridad.png'),
      'description': '',
      'brand': '',
      'product': null,
      'productImg': null,
      'quantity': 1,
      'itemsCart': [], // productos que tiene el carro.
      'meses': [this.$gettext('Ene'), this.$gettext('Feb'), this.$gettext('Mar'), this.$gettext('Abr'), this.$gettext('May'), this.$gettext('Jun'), this.$gettext('Jul'), this.$gettext('Ago'), this.$gettext('Sep'), this.$gettext('Oct'), this.$gettext('Nov'), this.$gettext('Dic')],
      'dosificacion_info': '',
      'empleo_info': '',
      'seguridad_info': '',
      'aSeguridad': [],
      'aObligacion': [],
      'formatoItem': '',
      'aFormato': [],
      'img': {
        'docDownload': require('@/assets/img/ficha/doc-download.svg')
      },
      specialPriceType: false
    }
  },
  created () {
    this.getProduct()
  },
  computed: {
    ...mapGetters([
      'role', 'isLoggedIn'
    ]),
    total () {
      // console.log(`formatoItem: ${this.formatoItem}`)
      // console.log(`price: ${this.price} , quantity: ${this.quantity}, formatoItem.quantity: ${this.formatoItem.quantity}`)
      if (this.formatoItem) {
        return this.price * this.quantity * this.formatoItem.quantity
      }
      return this.price * this.quantity
    },
    price () {
      let precio = this.product.price
      if (this.product.special_price !== null) {
        precio = this.product.special_price
        this.specialPriceType = true
        if (this.type_app === 'pedrosa' && this.role === 'client') {
          this.specialPriceType = false
        }
      }
      return precio
    },
    center () {
      return this.$store.state.center.center
    },
    isClient () {
      return this.$store.getters.isClient
    },
    isLead () {
      return this.$store.getters.isLead
    },
    hiddenAddCart () {
      // Si no hay stock y tiene activado el limite, ocultamos el botón de comprar.
      if (!this.product.stock && this.config_app.stock_limit) {
        return true
      }
      // Ocultamos el botón de añadir el carrito cuando no tengamos stock, obsolete_limit: true y obsolete: true
      if (!this.product.stock && this.product.obsolete && this.config_app.obsolete_limit) {
        return true
      }
      return false
    },
    limitQuantity () {
      // Si tenemos activo el limite lo ponemos en cada caja de cantidad.
      if (this.config_app.stock_limit) {
        return this.product.stock
      }

      // Si obsolete: true ponemos el liminte del stock a quantity
      return this.product.obsolete ? this.product.stock : ''
    },
    type_app () {
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    ecoCertificaciones () {
      return this.product.extra_info['Eco certificaciones'] ? this.product.extra_info['Eco certificaciones'] : []
    },
    aplicaciones () {
      return this.product.extra_info['Aplicaciones'] ? this.product.extra_info['Aplicaciones'] : ''
    },
    extraInfo () {
      let extraInfoFiltered = []
      Object.keys(this.product.extra_info).filter((e, index) => {
        if (!['Descripción','description','dosage','usage','Modo de empleo','Eco certificaciones','Nombre','Id','Aplicaciones','Orbita 1 id','Code internal','Code humans category child code',
        ,'Dosificacion','External published','Eco certification sheet','Video producto'].includes(e)) {
          extraInfoFiltered[e] = this.product.extra_info[e]
        }
      })
      return extraInfoFiltered
    }

  },
  methods: {
    getProduct () {
      const aSlug = this.id.split('-')
      SrvCatalog.getProduct(aSlug[0])
        .then(res => {
          // console.log('res-----> ', res)
          if (res.status === 200) {
            // console.log('--->Producto: ', res.data.results[0])
            this.product = res.data

            // console.log('--->Producto: ', this.product.extra_info)

            // console.log('--->Producto relacionados: ', this.product.related_products)

            // Migas
            this.toSendMigas(this.product.categories)

            // Descripción
            if (this.product['Descripción']) {
              this.description = this.product['Descripción']
            } else if (this.product.extra_info['Descripción'] !== undefined) {
              this.description = this.product.extra_info['Descripción']
            }
            // Marcas
            if (this.product.extra_info.brand !== undefined) {
              this.brand = this.checkEmpty(this.product.extra_info.brand)
            }

            // console.log('-----------', this.product)

            // Imagen
            /* if (this.config_app.extra_images && this.product.extra_images.length > 0) {
              // Mostramos la imagen del cliente (ej. primsa) en lugar de la de Orbita.
              this.productImg = this.product.extra_images[0]
            } else if (this.product.related_products.image_url !== undefined) {
              this.productImg = this.product.related_products.image_url
            } else {
              this.productImg = this.product.image_url
            } */

            if (this.product.image_url) {
              this.productImg = this.product.image_url
            } else if (this.config_app.extra_images && this.product.extra_images && this.product.extra_images.length > 0) {
              // Mostramos la imagen del cliente (ej. primsa) en lugar de la de Orbita.
              this.productImg = this.product.extra_images[0]
            } else {
              this.productImg = require('@/assets/img/no_disponible.png')
            }

            // formato
            this.aFormato = this.product.aditional_measurement_units
            if (this.aFormato && this.aFormato.length > 0) {
              // Unidad por defecto.
              const unit = this.productData.measurement_unit
              let formato = {'id': '', 'name': this.$gettext('Unidad'), 'quantity': 1}
              if (unit) {
                formato = {'id': '', 'name': unit.name, 'quantity': unit.quantity}
              }
              this.aFormato = [formato, ...this.aFormato]
              this.changeFormato(this.aFormato[0])
            }
            // console.log('aFormatos: ', this.aFormato)

            // Dosificación
            if (this.product.extra_info['dosage'] !== undefined) {
              this.dosificacion_info = this.product.extra_info.dosage
            }
            if (this.product.extra_info['Dosificacion'] !== undefined) {
              this.dosificacion_info = this.product.extra_info['Dosificacion']
            }

            

            // Modo de Empleo
            if (this.product.extra_info.usage !== undefined) {
              this.empleo_info = this.product.extra_info.usage
            }
            if (this.product.extra_info['Modo de empleo'] !== undefined) {
              this.empleo_info = this.product.extra_info['Modo de empleo']
            }
            

            // Seguridad
            if (this.product.extra_safety_symbols !== undefined) {
              this.seguridad_info = this.product.extra_safety_symbols

              if (this.seguridad_info) {
                this.seguridad_info.map(item => {
                  if (item.symbol_type_external_id === '2') {
                    this.aSeguridad.push(item)
                  } else if (item.symbol_type_external_id === '1') {
                    this.aObligacion.push(item)
                  }
                })
              }
            }
            // POSICIONAMOS SCROLL TOP
            window.scrollTo(0, 0)
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    checkEmpty (v) {
      return (!v) ? '-' : v
    },
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    },
    checkQuanty () {
      // obsolete_limit: true y product obsolete: true no dejamos que pase el limite del stock.
      if (this.product.obsolete && this.config_app.obsolete_limit) {
        this.quantity = Math.max(Math.min(Math.round(this.quantity), this.limitQuantity), 0)
      }
    },
    toAddCart () {
      // console.log(`Add Cart --- id product: ${this.product.id}, quantity: ${this.quantity}`)
      if (this.quantity > 0) {
        let item = {idProduct: this.product.id, quantity: this.quantity, product: this.product}
        if (this.config_app.formato && this.aFormato.length > 0) {
          item.aditional_measurement_unit = this.formatoItem.id
        }
        this.$emit('toAddCart', item)
      }
    },
    toGoProduct (id) {
      this.$emit('toGoProduct', id)
    },
    toSendMigas (migas) {
      this.$emit('toSendMigas', migas)
    },
    changeFormato (item) {
      // console.log(`id: ${item.id}, name: ${item.name}, quantity: ${item.quantity}`)
      this.formatoItem = item
    }
  },
  watch: {
    id () {
      this.formatoItem = ''
      this.quantity = 1
      this.getProduct()
    }
  }
}
</script>
<style lang="scss" scoped>

  .box-1{
    margin-top:10px;
    margin-bottom:40px;

    h3{
      color: #1C2C40;
      margin:0 0 22px;
      font-size: 18px;
      font-weight: $regular;

    }

    .descripction-cn{
      font-size: 14px;
      line-height: 24px;
      margin-bottom:40px;
    }
  }

  .holder-img{
    position:relative;
    border:1px solid #89ACE3;
    padding:10px;
    width: 100%;
    //max-width: 476px;
    height: 392px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

      img{
          max-width: 100%;
          height: auto;
          max-height: 380px;
      }

      i{
        position:absolute;
        bottom: 10px;
        right: 10px;
        font-size:30px;
        color:$grey-border;
        @include transition();
        cursor:pointer;
        display:none;

        &:hover, &:focus{
          color: $blue;
        }
      }

      & > small{
        position: absolute;
        z-index: 9;
        top:0;
        left:0;
        display: block;
        padding: 4px;
        color: $white;
        text-transform: uppercase;
        font-size:10px;
      }

      &.type-new{
        border-color: rgba(#8D22A7, .77);

        & > small{
          background-color: #4467C8;
        }
      }

      &.type-eco{
        border-color: #9CE16D;

        & > small{
          background-color: #9CE16D;
        }
      }

      &.type-outlet{
        border-color: #f0bd4e;

        & > small{
          background-color: #f0bd4e;
        }
      }
  }

  .info-cn{

    ul{
      margin-bottom:18px;
      padding: 0;
      min-height: 114px;

      li{
        list-style: none;
        font-size: 14px;
        line-height: 24px;
        strong{
          font-weight: $regular;
          margin-left: 8px;
        }
      }
    }

    .doc-cn{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      min-height: 40px;

      a{
        display: flex;
        justify-content: center;
        width: 48%;
        line-height: 40px;
        height: 40px;
        text-align: center;
        border: 1px solid $blue-light;

        img{
          margin-right: 8px;
        }

        &:hover, &:focus{
          border-color: darken($blue-light, 10%);
          text-decoration: none;
        }
      }
    }
  }

  .consumo-cn{
    margin-right: 0;
  }

  .consumo-data{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #EFEFEF;
    padding:12px;
    margin:0;
    text-align: center;
    font-weight: $medium;
    // min-height: 106px;

    span{
      display: inline-block;
      margin: 0 3px 5px;
    }
    strong, small{
      display:block;
      color:#494f58;
    }

    strong{
      font-weight: $regular;
      // margin-bottom:18px;
    }
  }

  .info-addCart{
    background-color: #EBF3FE;
    padding:20px 22px 26px;

    form{
      label{
        font-size: 14px;
        font-weight: $regular;
      }
    }
  }

  .pvp{
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;


    strong{
      font-size: 16px;
      font-weight: $medium;
    }
  }

  .und{
    font-size:16px;
    color:#2E811A;
    margin-bottom: 16px;

    &.noStock{
      color:#ff5466;
    }

    &.normalStock{
      color: $color-text;
    }
  }

  .formato{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cant{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;

    span{
      display: inline-block;
    }

    input{
      width:60px;
      border:none;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border: 1px solid #E6E8E9;
      font-size: 13px;
      @include roundAll(4);
      overflow: hidden;
      padding-left: 10px;
    }
  }

  .pvpTotal{
    text-align: right;
    margin-bottom: 26px;

    span{
      display:inline-block;
      font-size: 20px;
      font-weight: $medium;
      display: inline-block;
      color: #303030;

      &.special{
        padding: 4px 8px;
        color: #fff;
        background-color: $color-precio-special!important;
        @include roundAll(3);
      }

      &.obsolete{
        color: #f0bd4e;
      }

    }
  }

  .submit{
    margin-bottom:0;
  }

  .btn-orange{
    margin-bottom:0;
    width: 100%;
    text-transform: uppercase;
  }

  .ecoCertificaciones{
    margin-bottom: 30px;
    .row-flex{
      justify-content: left;
    }
    h3{
      font-size: 16px;
      font-weight: $medium;
      margin-top: 30px;
      margin-bottom: 18px;
    }
    img{
      height: 90px;
    }
  }

  .especificaciones-cn{
    margin-bottom: 30px;
    h3{
      font-size: 16px;
      font-weight: $medium;
      margin-top: 30px;
      margin-bottom: 18px;
    }

    ul{
      padding:0;
      list-style: none;
      align-items: initial;

      li{
        width: 48%;
        padding:6px 20px;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        // align-items: center;

        p{
          margin-bottom: 0;
          display: flex;
          width: 100%;

          & > *{
            width: 50%;
            display: inline-block;
          }

          strong{
            font-weight: $regular;
          }
        }

       &:nth-child(1), &:nth-child(2){
          width: 100%;
          p{
            span{
              width: 23%;
            }
            strong{
              width: 77%;
            }
          }
        }

        &:nth-child(1), &:nth-child(3), &:nth-child(4), &:nth-child(7), &:nth-child(8),
        &:nth-child(11), &:nth-child(12), &:nth-child(15), &:nth-child(16), &:nth-child(19), &:nth-child(20),
        &:nth-child(23), &:nth-child(24), &:nth-child(27), &:nth-child(28), &:nth-child(31), &:nth-child(32),
        &:nth-child(35), &:nth-child(36), &:nth-child(39), &:nth-child(40), &:nth-child(43), &:nth-child(44),
        &:nth-child(47), &:nth-child(48), &:nth-child(51), &:nth-child(52), &:nth-child(55), &:nth-child(56)
        {
          background-color: #F6F6F6;
        }
      }
    }
  }

  .box-3{
    padding-bottom:40px;
    text-align:center;

    h2{
      font-size:18px;
      font-weight: $medium;
      margin:20px 0 20px;
    }

    .list-related-product{
      padding: 30px 26px 18px;
      background-color:#F5F5F5;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      flex-wrap: wrap;
    }

    .item-cn{
      display: inline-block;
      margin:0 10px;
        .catalog-GridItem{
          background-color: $white;
        }
    }
  }

  .item-ico{
    &:not(:last-child){
      margin-bottom: 20px;
    }
  }


</style>
