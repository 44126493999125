<template lang="pug">
  #catalog-cart
    // .cart(:class="{'open': showRoll}")
    .cart(:class="{'open': showRoll}" @mouseover="openRoll" @mouseleave="closeRoll")
        p(@click="goOrden()")
          span.ico-cart(:data-item="cartCount")
            img(:src="img.carro" width="28")
        p(@click="goOrden()")
          strong #[translate Carro]
          small {{total | format-money}} {{moneda}}
        .roll(:class="{'show':showRoll}")
          .roll-head
            strong
              translate Productos en el carro
            button.btn-close( @click="closeRoll" )
              img(:src="img.close" width="12")
          .scroll-cn
              .roll-item(v-for="item in items" )
                picture.img-cn(:class="{'type-outlet': item.product_data.is_outlet, 'type-eco': item.product_data.is_eco}" @click="goProduct(item)" )
                  img(:src="checkEmptyImg((item.product_data.image_url))")
                  small(v-if="item.product_data.is_eco") #[translate Eco]
                  small(v-if="item.product_data.is_outlet") #[translate Outlet]
                div.info-cn
                  .title-cn
                    h3(@click="goProduct(item)") {{item.product_data.name}}
                      br
                      small(v-if="item.description")  {{item.description}}
                    p
                      button.btn-remove(@click="removeProduct(item)")
                        img(:src="img.basura" width="16")
                  .max-cn
                    ul
                      li(v-if="config_app.stock_availability") to be delisted XXXX
                      li(v-else-if="!productTypeCargos.includes(item.product_data.id)") #[translate Stock]
                        small.und(:class="{'noStock': !item.product_data.stock}") &nbsp;{{item.product_data.stock}}
                      // li #[translate Prov. ]:
                        small &nbsp;{{supplierName(item.product_data.supplier)}}
                      // li #[translate Código]:
                        small &nbsp;{{checkEmpty(item.product_data.external_id)}}
                      li(v-if="item.aditional_measurement_unit_data") {{item.aditional_measurement_unit_data.name}} #[translate de] {{item.aditional_measurement_unit_data.quantity}} #[translate uds].
                      li #[translate Cantidades]: {{item.quantity}}

                    .Precio-box
                      span.pvp(:class="{'special':specialPriceType(item), 'no-stock': !item.product_data.stock, 'obsolete': item.product_data.obsolete}") {{price(item) | format-money}} {{moneda}}
          .roll-total
            button.btn.btn-orange(@click="goOrden()")
              translate Ver carro
            p {{numItems}} #[translate productos]: {{base | format-money}} {{moneda}}
              br
              | #[translate IVA]: {{tax | format-money}} {{moneda}}
              br
              |
              strong #[translate Total]: {{total | format-money}} {{moneda}}
              //br
              |
              button(class="btn btn-trans" @click="deleteCart()")
                translate Vaciar carro
    bk-cart-notify(:add="addItem" :type='typeNotify')
</template>
<script>
import {mapGetters} from 'vuex'
import BkCartNotify from '@/components/catalog/Cart-notify.vue'

export default {
  name: 'CatalogCart',
  components: { BkCartNotify },
  props: [],
  data () {
    return {
      showRoll: false,
      addItem: '',
      img: {
        close: require('@/assets/img/icos/cerrar-01.svg'),
        carro: require('@/assets/img/icos/carro.svg'),
        basura: require('@/assets/img/icos/basura-01.svg')
      },
      productTypeCargos: [1, 2413, 3960]
    }
  },
  created () {
    // console.log(`type: ${this.type}`)
  },
  computed: {
    ...mapGetters([
      'role'
    ]),
    numItems () {
      return this.items.length
    },
    center () {
      return this.$store.getters.center
    },
    isClient () {
      return this.$store.getters.isClient
    },
    isLead () {
      return this.$store.getters.isLead
    },
    cart () {
      return this.$store.getters.cart
    },
    idCart () {
      return this.cart.idCart
    },
    base () {
      return this.cart.base
    },
    tax () {
      return this.cart.tax
    },
    total () {
      return this.cart.total
    },
    moneda () {
      return this.cart.moneda
    },
    items () {
      const aItems = this.cart.items
      return aItems
    },
    cartCount () {
      return this.$store.getters.count
    },
    addItemCart () {
      return this.$store.getters.addItemCart
    },
    typeNotify () {
      // console.log('----' + this.isLead)
      if (this.isLead) {
        return 'lead'
      }
      return ''
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    type_app () {
      return this.$config.type
    }
  },
  methods: {
    openRoll () {
      this.showRoll = true
      // document.body.classList.add('no-scroll')
      document.getElementById('app-bg-hit').classList.add('show')
    },
    closeRoll () {
      this.showRoll = false
      // document.body.classList.remove('no-scroll')
      document.getElementById('app-bg-hit').classList.remove('show')
    },
    supplierName (item) {
      if (item) {
        return item.name
      }
      return '-'
    },
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    },
    checkEmpty (v) {
      return (!v) ? '-' : v
    },
    deleteCart () {
      this.$store.dispatch('deleteCart')
    },
    removeProduct (product) {
      // console.log('removeProduct:', product)
      this.$store.dispatch('removeCartProduct', product)
    },
    goOrden () {
      this.closeRoll()
      if (this.isLead) {
        // console.log(`Carrito type=lead`)
        this.$router.push({'name': 'leadOfertaCartList'})
      } else {
        this.$router.push({'name': 'cartList'})
      }
    },
    goProduct (item) {
      const id = item.product_data.id
      if (this.productTypeCargos.includes(id) && this.isLead) {
        this.$router.push({'name': 'leadOfertaCartList'})
      } else if (this.productTypeCargos.includes(id)) {
        this.$router.push({'name': 'cartList'})
      } else if (this.isLead) {
        this.$router.push({'name': 'leadProductSingle', 'params': {'id': id.toString()}})
      } else {
        this.$router.push({'name': 'productSingle', 'params': {'id': id.toString()}})
      }
    },
    price (item) {
      let value = item.price
      if (item.aditional_measurement_unit_data) {
        value = value * item.aditional_measurement_unit_data.quantity
      }
      return value
    },
    specialPriceType (item) {
      let isSpecial = false
      if (item.is_special_price) {
        isSpecial = true
        if (this.type_app === 'pedrosa' && this.role === 'client') {
          isSpecial = false
        }
      }
      return isSpecial
    }
  },
  watch: {
    cart (newValue, oldValue) {
      // console.log('watch cart --> oldValue: ', oldValue)
      // console.log('watch cart --> newValue: ', newValue)
    },
    items (newValue, oldValue) {
      // console.log('watch items --> oldValue: ', oldValue)
      // console.log('watch items --> newValue: ', newValue)
    },
    cartCount (newValue, oldValue) {
      // console.log('watch cartCount --> oldValue: ', oldValue)
      // console.log('watch cartCount --> newValue: ', newValue)
    },
    addItemCart (newValue, oldValue) {
      // console.log('watch addItemCart --> oldValue: ', oldValue)
      // console.log('watch addItemCart --> newValue: ', newValue)
      this.addItem = newValue
    }
  }
}
</script>
<style lang="scss" scoped>

  #catalog-cart{
    position:relative;
  }

  .cart{
    font-size: 16px;
    color: #303030;
    cursor: pointer;
    @include transition();
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:hover, &:focus, &.open{
      .ico-cart{
        &:after{
          background-color: $blue-light;//#00ADFC;
        }
      }

      strong{
        color: $blue-light; //#00ADFC;
      }

      p{
        padding: 40px 8px;
      }
    }

    p{
      margin: 0;
      padding: 0 8px;

      &:first-child{
        width: 36px;
      }
    }

    .ico-cart{
      position:relative;
      font-size:28px;

      &:after{
        content: attr(data-item);
        position: absolute;
        top: -15px;
        right: -30px;
        font-family: $font;
        font-size: 10px;
        line-height: 10px;
        min-width: 22px;
        min-height: 22px;
        text-align: center;
        padding: 6px;
        background-color: $orange;//#00ADFC;
        color: $white;
        @include roundAll100(50%);
        @include transition();
      }
    }

    strong{
      font-size: 12px;
      display:block;
      font-weight: $regular;
      @include transition();
    }
    small{
      font-size: 12px;
      display: block;
    }

    &:hover, &:focus{
      i{
        &:after{
          transform: scale(1.2);
        }
      }
    }
  }

  .roll{
    position:absolute;
    z-index: 10;
    top:100px;
    right:0; // -500px;
    width:370px;
    background-color: $white;
    border:1px solid #979797;
    @include roundAll(2);
    @include transition(0.6);
    // @include opacity(0);
    @include box-shadow(all, 15px, rgba($black, 0.1) );
    display: none;

    @include breakpoint(large){
      // width:100%;
    }

    &.show{
      // right:0;
      //@include opacity(1);
      display: block;
    }
  }

  .roll-head{
    font-size: 14px;
    padding: 16px;
    border-bottom: 1px solid $grey-border;

    strong{
      font-weight: $regular;
    }
  }

  .roll-list{
    margin-bottom:20px;
    border-bottom: 1px solid $grey-border;
  }

  .roll-item{
    position: relative;
    display: flex;
    min-height: 126px;
    border: 1px solid #ffffff;
    border-bottom: 1px solid $grey-border;
    padding:18px 20px 10px 126px;
    color: #303030;
    cursor: pointer;
    @include transition();

    &:hover, &:focus{
      background-color: rgba(18, 159, 233, 0.2);
      border-color: $blue-light;
    }

    @include breakpoint(large){
    }

    &:last-child{
      margin-bottom:0px;
    }

    h3{
      font-size:13px;
      line-height: 18px;
      font-weight: $regular;
      text-transform: uppercase;
      margin-top:0;
      margin-bottom:10px;

      small{
        font-size: 12px;
        text-transform: none;
      }
    }

    ul{
      margin:0;
      padding:0;
      list-style: none;
    }

    li{
      font-size: 13px;

      small{
        /// font-size: 12px;
        // font-weight: $light;
      }
    }

    .img-cn{
      position:absolute;
      top:10px;
      left:5px;
      width: 100px;
      height: 100px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      @include roundAll(2)
      overflow: hidden;
      border: 1px solid #fff;

      @include breakpoint(large){
        left:10px;
      }

      img{
        max-height: 110px;
      }

      & > small{
        position: absolute;
        z-index: 9;
        top:0;
        left:0;
        display: block;
        padding: 4px;
        color: $white;
        text-transform: uppercase;
        font-size:10px;
      }

      &.type-new{
        border-color: rgba(#8D22A7, .77);

        & > small{
          background-color: #4467C8;
        }
      }

      &.type-eco{
        border-color: #9CE16D;

        & > small{
          background-color: #9CE16D;
        }
      }

      &.type-outlet{
        border-color: #f0bd4e;

        & > small{
          background-color: #f0bd4e;
        }
      }
    }
  }

  .und{
      font-size:14px;
      color:#61c844;


      &.noStock{
        color:#ff5466;
      }
  }

  .btn-close{
    position:absolute;
    top: 14px;
    right: 14px;
    font-weight: $medium;
    font-size: 16px;
    border:none;
    background-color: transparent;
    color: $blue-light;
    @include transition();

    &:hover, &:focus{
      color: $blue;
    }
  }

  .info-cn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .title-cn{
      display: flex;
      justify-content: space-between;

      p{
        padding: 0;
        width: 64px;
        text-align: right;
      }
    }
  }

  .max-cn{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    small{
      display: inline-block;
      margin-left: 6px;
    }
  }

  .roll-total{
    position: relative;
    padding:14px 20px;
    font-size: 13px;
    color: #494f58;
    background-color: $grey-bg;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p{
      width: 50%;
      margin: 0;
      padding: 0 !important;
    }

    .btn-trans{
      display: none;
    }

    strong{
      font-weight: $medium;
    }

    .btn{
      font-weight: $regular;
      font-size: 13px;
    }
  }

  .btn-action{
    position:absolute;
    top:28px;
    right:30px;
  }

  .btn-remove{
    color: $blue-light;
    font-size: 14px;
    background-color: transparent;
    border:none;
    padding: 0 6px 0;
    text-align: right;
    line-height: 34px;

    &:hover, &:focus{
      color: $blue;
    }
  }

  .scroll-cn{
    max-height: 280px;
    overflow: hidden;
    overflow-y: auto;
  }

</style>
