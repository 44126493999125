<template lang="pug">
  .ClientListItem(:class="{'type-pendiente': typeItem === 'pendientes', 'type-validado': typeItem === 'validados'}")
    .container
      .row.box-1(data-toggle="collapse" :data-target="`#demo-${typeItem}-${index}`" @click="actionShowDetalle")
        .col-sm-4
          p.name {{order.center.name}}
        .col-sm-3.mail
          p {{order.user_creator && order.user_creator.first_name}} {{order.user_creator && order.user_creator.last_name}}
            a(:href="'mailto:' + order.user_creator.email" target="_blank" v-if="order.user_creator && order.user_creator.email") {{order.user_creator.email}}
        .col-sm-2
          p.text-center {{order.order_datetime | moment("DD-MM-YYYY")}}
        .col-sm-1
          p.text-center {{order.total | format-money}} {{order.currency_symbol}}
        .col-sm-2.delivery
          div.text-center
            p.date-cn(v-if="typeItem === 'validados'" :title="order.delivery_datetime | moment('DD-MM-YYYY')") {{order.delivery_datetime | moment("DD-MM-YYYY")}}
            p.state-cn(v-if="typeItem === 'pendientes'" :class="state") {{stateLabel}}
            span.img-cn.tw-flex
              img(:src="img.derecha" width="8")
            // button.btn.btn-action(@click="goPedido") #[translate +]

      .row.box-2(:id="`demo-${typeItem}-${index}`" class="collapse")
        .col-sm-12
          bk-grid(:id="order.id" :type="typeItem" :showDetalle="showDetalle")
</template>
<script>
import BkGrid from '@/components/validar-pedidos/Home-listado-detalle.vue'

export default {
  name: 'ValidarPedidosListItem',
  props: ['order', 'typeItem', 'index'],
  components: { BkGrid },
  data () {
    return {
      state: null,
      showDetalle: false, // para que el componente BKGrid no haga la petición y traíga el detalle.
      img: {
        derecha: require('@/assets/img/icos/derecha.svg')
      }
    }
  },
  created () {
    // console.log(`---> `, order)
    this.state = this.order.state
  },
  computed: {
    stateLabel () {
      let result = '-'
      if (this.order.state_values) {
        this.order.state_values.map(item => {
          if (item.code === this.state) {
            result = item.value
          }
        })
      }
      return result
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  methods: {
    goPedido () {
      // console.log('Order seleccionada: ', this.order.id)
      this.$router.push({name: 'validarPedidoSingle', params: {id: this.order.id, type: this.typeItem}})
    },
    actionShowDetalle () {
      this.showDetalle = true
    }
  }
}
</script>
<style lang="scss" scoped>
.ClientListItem{
  color:#494f58;

  &.type-pendiente{
    position: relative;

    .name {
      &:before{
        content: '';
        position: absolute;
        top:0;
        bottom: 0;
        left:6px;
        width: 9px;
        height: 9px;
        @include roundAll100(50%);
        background-color: $orange;
        margin: auto;
      }
    }

  }
}

.box-1{
  @include transition();
  font-size: 14px;
  color: #4D4F5C;
  font-weight: $regular;
  border:1px solid #fff;
  cursor: pointer;

  & > *{
    padding-top:12px;
    padding-bottom:8px;
  }

  &:hover, &:focus{
    background-color: rgba(#129FE9, .2);
    border:1px solid #00ADFC;

    .name{
      font-weight: $medium;
    }
  }

  p{
    margin:0;

    &.name{
      text-transform: uppercase;
      @include ellipsis();
      cursor: pointer;
      padding-left: 8px;
    }
  }

  .mail{
    padding-left: 0;
    padding-right: 0;

    p{
      @include ellipsis();
    }

    a{
      display: inline-block;
      margin-left: 10px;
      color: $blue-light;
    }
  }
}

.delivery{
  position: relative;
  .date-cn{
    @include ellipsis();
    padding-right: 20px;
  }
}

.img-cn{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  height: 22px;
  margin: auto;
  img{
    @include transition();
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

[aria-expanded="true"]{
    img{
      -moz-transform: rotate(90deg) translate(0px);
      -o-transform: rotate(90deg) translate(0px);
      -ms-transform: rotate(90deg) translate(0px);
      -webkit-transform: rotate(90deg) translate(0px);
      transform: rotate(90deg) translate(0px);
    }
}

.date-cn, .num-cn{
  padding-left:0;
  padding-right: 0;
}

.state-cn{
  color: $orange;

  &.SERVIDO, &.S{
    color: $green;
  }
}

.box-2{
  padding: 16px 0 50px;
  background-color: #fff;
  border-bottom: 1px solid #F5F6FA;
  color: #4D4F5C;
  font-weight: $light;

  strong{
    font-weight: $regular;
  }
}
</style>
