<template lang="pug">
  .leadOferta
    .box-1
      .container
        .row
          .col-sm-12
            p {{name}}

    .box-2
      .container
        .row
          .col-sm-8
            bk-search(@toSearchCategory="toSearchCategory" :categories="aCat" :category="catActive" type="lead")

    // Mostrar listado
    .box-3(v-show="$route.name !== 'leadProductSingle'")
      .container
        .row
          .col-sm-3.sidebar-cn(v-if="role !== 'client'")
            bk-filter-sidebar(@toFilterSuppliers="toFilterSuppliers" @toFilterCategory="toFilterCategory" @toGetMigas="toGetMigas" @toGetCat="toGetCat" :query="query" :category="filterCategory" :typeProduct="typeProduct")
          .holder-catalogo(:class="{'col-sm-9': role !== 'client', 'col-sm-12': role === 'client'}")
            bk-migas(v-if="migas.length > 1000" :migas="migas" :showHidenDetails="idProduct" @toHidenDetails='hidenDetails' @toFilterCategory="toFilterCategory")
            .filter-grid
              bk-filter-top(@toChangeView="toChangeView" @toChangeFilterProduct="toChangeFilterProduct" @toChangeOrdering="toChangeOrdering" :view="viewGrid")
              bk-grid(:view="viewGrid" :category="catActive" :query="query" :ordering="ordering" :typeProduct="typeProduct" @toAddCart="toAddCart" @toGoProduct="toGoProduct" @toFilterCategory="toFilterCategory")

    // Mostramos el detalle del producto.
    div(v-if="$route.name === 'leadProductSingle'")
      transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
        router-view



</template>
<script>
import {mapGetters} from 'vuex'

import BkSearch from '@/components/catalog/Search.vue'
import BkMigas from '@/components/catalog/Migas-list.vue'
import BkFilterSidebar from '@/components/catalog/Filter-sidebar.vue'
import BkFilterTop from '@/components/catalog/Filter-top.vue'
import BkGrid from '@/components/catalog/Grid.vue'

export default {
  name: 'LeadOferta',
  components: { BkSearch, BkMigas, BkFilterSidebar, BkFilterTop, BkGrid },
  data () {
    return {
      viewGrid: 'grid',
      filterSuppliers: '',
      filterCategory: '',
      query: '',
      aCat: [],
      catActive: null, // guardamos la categoría activa.
      idProduct: 0, // Cuando asignemos un ID se vera el detalle del producto con ese ID.
      migas: [],
      ordering: '', // Ordenación de producto
      typeProduct: '' // Si es autorizado o no.
    }
  },
  computed: {
    ...mapGetters([
      'role'
    ]),
    lead () {
      return this.$store.getters.lead
    },
    id () {
      return this.lead.id
    },
    name () {
      return this.lead.name
    }
  },
  methods: {
    toChangeView (type) {
      // console.log(`---- ${type}`)
      this.viewGrid = type
    },
    toFilterSuppliers (v) {
      this.filterSuppliers = v
    },
    toFilterCategory (item) {
      // console.log('toFilterCategory:', item)
      // this.query = '' // Para resetear la busqueda.
      this.filterCategory = (item) ? item.id : ''
      this.catActive = item || null
      this.idProduct = 0 // para quitar el detalle del producto si esta activo
      this.filterSuppliers = '' // Al cambiar de categoría dejamos el proveedor sin marcar porque quizas en esa categoría no haya.
    },
    toGetMigas (v) {
      // console.log('*****', v)
      this.migas = v[0]
      this.idProduct = 0 // para quitar el detalle del producto si esta activo
      this.filterSuppliers = '' // Dejamos el proveedor sin marcar porque quizas no exista en esta busqueda.
    },
    toGetCat (aCat) {
      this.aCat = aCat
    },
    toSearchCategory (aDatos) {
      // console.log(`query: ${aDatos[0]}, idCat: ${aDatos[1]}`)
      if (this.$route.name === 'leadProductSingle') {
        this.$router.push({'name': 'leadOferta'})
      } else {
        // si estamos en el listado cerramos todas las cateogías
        // window.$(`#catalog-menu button`).removeClass('open')
        // window.$(`#catalog-menu ul`).removeClass('in')
      }

      this.query = aDatos[0]
      this.filterCategory = aDatos[1] // asignar categoría, si viene categoría del buscador.
      this.idProduct = 0 // para quitar el detalle del producto si esta activo
      this.filterSuppliers = '' // Dejamos el proveedor sin marcar porque quizas no exista en esta busqueda.
    },
    toAddCart (item) {
      // console.log(`Component page/CatalogoList -> id product: ${item.idProduct}, quantity: ${item.quantity} , item: `, item.product)
      // Envia al carrito
      this.$store.dispatch('addCart', {idProduct: item.idProduct, quantity: item.quantity, product: item.product})
    },
    toGoProduct (id) {
      // console.log(`-----> toGoProduct: ${id}, migas: `, this.migas)
      this.idProduct = id
      const aMigasSlug = this.generateMigasSlug()
      // console.log('---------aMigasSlug: ', aMigasSlug)
      // Enviamos a la store  el slug de las migas.
      this.$store.dispatch('setCatalogueMigasSlug', aMigasSlug)
      this.$router.push({'name': 'leadProductSingle', 'params': {'id': id.toString(), 'migas': this.migas}})
    },
    hidenDetails () {
      this.idProduct = 0
    },
    toChangeFilterProduct (v) {
      // console.log(`toChangeFilterProduct: ${v}`)
      this.typeProduct = v
    },
    toChangeOrdering (v) {
      // console.log(`changeOrderProduct: ${v}`)
      this.ordering = v
    },
    generateMigasSlug () {
      let aMigasSlug = ''
      this.migas.map((item) => {
        // console.log(`item migas: ${item.id}`)
        aMigasSlug += (aMigasSlug === '') ? item.id : '-' + item.id
      })
      // console.log('---------aMigasSlug: ', aMigasSlug)
      return aMigasSlug
    }
  },
  watch: {
    // Viene de la página de productosSingle cuando pulsas en las migas.
    catProductSingle () {
      // console.log(`catProductSingle: ${this.catProductSingle}`)
      // const value = (this.catProductSingle) ? this.catProductSingle : ''
      // this.toFilterCategory(value)
    },
    '$route' (to, from) {
      // react to route changes...
      if (to.name === 'leadOferta') {
        // Si volvemos al catálogo desde el producto. Ponemos el id de producto a 0 para que se vean las migas.
        this.idProduct = 0
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .leadOferta{
    position:relative;
    z-index:1;
  }
  .filter-grid{
    background-color: #FAFAFA;
    padding: 12px 17px 0;
  }
  .box-1{
    background-color: rgba(#EFEFEF, .33);

    p{
      font-size: 18px;
      font-weight: $medium;
      margin: 14px 0 10px;
    }

  }

  .box-2{
    background-color: #EFEFEF;
    padding:14px 0;
  }

  .box-3{
    .sidebar-cn{
      padding-right: 0;
    }
    .holder-catalogo{
      padding-left:0;
    }
  }
</style>
