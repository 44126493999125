<template lang="pug">
  .homeListadoDetalle
    .container
      .row.grid-head
        .col-sm-3
          p #[translate Descripción]
        .col-sm-3
          p #[translate Código]
        .col-sm-2
          p.text-center #[translate Cantidad]
        .col-sm-2
          p.text-center #[translate Fecha entrega/prevista]
        .col-sm-2
          p.text-right #[translate Precio]

    .grid-cn
      bk-item(v-for="item in aProduct" :key="item.id" :entries="item" :billable="isFacturable" :type="type" :fecha="fecha" :currency_symbol="currency_symbol")

    .container.box-3
      .row
        .col-sm-8.obser-cn
          p(v-if="details")
            strong #[translate Observaciones]:
            small {{details}}
        .col-sm-4
          ul
            li {{numItems}} #[translate Productos]: #[small {{base | format-money}} {{currency_symbol}}]
            li #[translate IVA]: #[small {{tax | format-money}} {{currency_symbol}}]
            li #[translate TOTAL] #[small {{total | format-money}} {{currency_symbol}}]

    .container.box-4
      .row
        .col-sm-8.text-right.pull-right
            button.btn.btn-action(@click="goPedido") #[translate Ver Pedido]
</template>

<script>
import BkItem from '@/components/validar-pedidos/Home-item-detalle.vue'

import SrvValidarPedidos from '@/services/validar-pedidos'

export default {
  name: 'ValidarPedidoHomeListadoDetalle',
  props: ['id', 'type', 'showDetalle'],
  components: { BkItem },
  data () {
    return {
      number: null,
      fecha: null,
      center: null,
      state: null,
      state_values: null,
      base: null,
      tax: null,
      total: null,
      details: null, // descripción
      numItems: null,
      billable_center: null, // Si es facturable el centro.
      currency_symbol: null, // moneda
      messageError: null,
      aProduct: [],
      enviando: false
    }
  },
  created () {
    if (this.showDetalle) {
      this.getPedido()
    }
  },
  computed: {
    isFacturable () {
      return this.billable_center
    }
  },
  methods: {
    getPedido () {
      // console.log('getPedido - id: ' + this.id)
      SrvValidarPedidos.getPedido(this.id, this.type)
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            // console.log(`Pedido single: `, data)
            this.number = data.number
            this.fecha = data.delivery_datetime
            this.center = data.center
            this.state = data.state
            this.state_values = data.state_values
            this.base = data.base
            this.tax = data.tax_total
            this.total = data.total
            this.aProduct = data.entries
            this.numItems = this.aProduct.length
            this.details = data.details
            this.billable_center = data.billable_center
            this.currency_symbol = data.currency_symbol
            // console.log(`number `, res)
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    goPedido () {
      // console.log('Order seleccionada: ', this.id)
      this.$router.push({name: 'validarPedidoSingle', params: {type: this.type, id: this.id}})
    }
  },
  watch: {
    showDetalle () {
      if (this.showDetalle) {
        this.getPedido()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .homeListadoDetalle{
    max-width: 1100px;
    margin: 20px auto 0;
    padding-bottom: 50px;
    border-bottom: 1px solid #1C2C40;
  }

  .homeItemDetalle{

    &:nth-child(odd){
      background-color: #F5F7F9;
    }

    &:last-child{

    }
  }

  .box-3{
    border-bottom: 1px solid rgba(#545454, .46);
    padding-bottom:20px;
    margin:12px 0 20px;

    ul{
      padding-left: 0;
      list-style: none;
    }

    li{
      color: #5b5d5e;
      font-size: 14px;
      margin-bottom: 2px;

      small{
        font-size: 14px;
        float: right;
      }

      &:last-child{
        font-weight: bold;
      }
    }

    .obser-cn{
      strong{
        font-weight: $regular;
        color: #494f58;
        font-size: 14px;
        vertical-align: top;
        margin-right: 10px;
      }

      small{
        display: inline-block;
        font-size: 14px;
        width:70%;
        height: 80px;
      }
    }
  }

  .box-4{
    .btn{
      transform: translateX(15px);
    }
  }


</style>
