<template lang="pug">
  #EstadisticasCategorias

    .Loader-cn(:class="{'show': apiSend}")
      span.Loader-spinner

    .box-1
      .container
        .row
          .col-sm-12
            h2 #[translate Compras por categoría]
        .row
          .col-sm-8
            bk-migas
          .col-sm-4.text-right
            date-picker(class="calendar-cn" v-model="calendar.date" :lang="calendar.lang" :format="calendar.format" type="month" range :clearable="calendar.clearable" :shortcuts="calendar.shortcuts" not-before="2016-01-01" @input="changeCalendar" confirm)

    .container.box-3
      .row
        .col-sm-1
        .col-sm-5
          h4 #[translate Período] #[strong {{number(oData.period_purchases)}}€]
          ul.chart-legend
            li(v-if="oData.period_categories_purchases" v-for="(item, index) in oData.period_categories_purchases")
              p #[em {{item.pruchases_percentage}}%] #[span ] {{item.name}}: #[strong {{number(item.purchases)}} €]
          .chart-data(v-if="donutLeft.show")
            donut-general(:valuesData="donutLeft")
        .col-sm-1
        .col-sm-5
          h4.two-cn #[translate Año anterior] #[strong {{number(oData.previous_period_year_purchases)}}€]
          ul.chart-legend.two-cn
            li(v-if="oData.previous_period_year_categories_purchases" v-for="(item, index) in oData.previous_period_year_categories_purchases")
              p #[em {{item.pruchases_percentage}}%] #[span ] {{item.name}}: #[strong {{number(item.purchases)}} €]
          .chart-data(v-if="donutRight.show")
            donut-general(:valuesData="donutRight")

</template>
<script>
import appConfig from '@/config-app.js'
import * as types from '@/store/mutation-types'
import BkMigas from '@/components/client/estadisticas/migas'
import DonutGeneral from '@/components/dashboard/chart/DonutMultiple'
import DatePicker from 'vue2-datepicker'

// importamos servicios estadisticas
import SrvEstadisticas from '@/services/estadisticas'

export default {
  name: 'EstadisticasCategorias',
  components: {BkMigas, DonutGeneral, DatePicker},
  data () {
    return {
      'apiSend': false, // lo ponemos en true cuando enviemos peticiones a la API para mostrar el preload.
      'oData': { // Objeto dónde guardaremos los datos de la API.
        'period_categories_purchases': [],
        'previous_period_year_categories_purchases': [],
        'period_purchases': 0,
        'previous_period_year_purchases': 0
      },
      'dataYear': [], // guardamos los datos en un array temporal para que tengan el mismo orden que el array del periodo.
      'donutLeft': {
        'labels': [],
        'values': [],
        'colors': [],
        'show': false
      },
      'donutRight': {
        'labels': [],
        'values': [],
        'colors': [],
        'show': false
      }
    }
  },
  created () {
    // console.log('-------', this.dashboardStore.node_name)
    this.getClientPurchasesCategories()
  },
  computed: {
    migasId () {
      // return  ['Total', 'Barcelona', 'ARGENTONA', '40638']
      return this.$store.state.client.clientDashboardOptions.migasId
    },
    calendar () {
      let before = this.$store.state.client.clientDashboardDate.before
      let after = this.$store.state.client.clientDashboardDate.after
      // console.log(`-----> periodo desde ${after} hasta ${before}`)
      return {
        'before': before,
        'after': after,
        'date': [after, before],
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'MM/YYYY' // 'MMM YYYY'
      }
    }
  },
  methods: {
    getClientPurchasesCategories () {
      this.apiSend = true // mostramos el preload
      // Generamos el menu dle sidebar izquierdo
      // console.log(`migasId: ${this.migasId}`)
      SrvEstadisticas.getClientPurchasesCategories(this.migasId, this.calendar.before, this.calendar.after)
        .then(res => {
          if (res.status === 200) {
            // console.log('------------ toSearch ---------------')
            // console.log('--->Data Dashboard Resumen: ', res.data)
            this.oData = res.data
            this.donutLeft = {
              'labels': [],
              'values': [],
              'colors': [],
              'show': false
            }

            this.donutRight = {
              'labels': [],
              'values': [],
              'colors': [],
              'show': false
            }

            let donutLeft = this.oData.period_categories_purchases
            let donutRight = this.oData.previous_period_year_categories_purchases

            if (donutLeft.length) {
              this.donutLeft.show = true
              this.donutLeft.colors = ['#5C9BD5', '#EB7B34', '#A4A5A6', '#F9C40B', '#F7A591', '#42D0C2']
              donutLeft.map((item, index) => {
                // nombre del Periodo
                this.donutLeft.labels.push(item.name)
                // datos del Periodo
                this.donutLeft.values.push(item.pruchases_percentage)

                // datos del año anterios.
                /* let itemYear = donutRight.filter(v => v.id === item.id)
                if (itemYear.length) {
                  this.donutRight.values.push(itemYear[0].pruchases_percentage)
                  console.log(`--- item: ${item.id}, itemYear:`, itemYear[0].pruchases_percentage)
                } */
              })
            }

            if (donutRight.length) {
              this.donutRight.show = true
              this.donutRight.colors = ['#5C9BD5', '#EB7B34', '#A4A5A6', '#F9C40B', '#F7A591', '#42D0C2']
              donutRight.map((item, index) => {
                // console.log('---', item) // date, num_orders, value_orders
                // if (index < 6) {
                this.donutRight.labels.push(item.name)
                this.donutRight.values.push(item.pruchases_percentage)
                // }
              })
            }
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
          this.apiSend = false // ocultamos el preload
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    changeCalendar (value) {
      let aDate = value.toString().split(',')
      if (aDate.length === 2) {
        let after = this.$moment(aDate[0]).format('YYYY-MM-DD[T]HH:mm:ss')
        let before = this.$moment(aDate[1]).endOf('month').format('YYYY-MM-DD[T]HH:mm:ss')
        this.$store.commit(types.UPDATE_CLIENT_DASHBOARD_DATE, {'after': after, 'before': before})
        // console.log(`${this.calendar.after}, ${this.calendar.before}`)
        this.getClientPurchasesCategories()
      }
    },
    number (num) {
      if (Number.isInteger(num)) {
        // sin decimales
        return this.$options.filters.formatNumber(num)
      } else {
        // con decimales
        return this.$options.filters.formatMoney(num)
      }
    }
  },
  watch: {
    migasId () {
      this.getClientPurchasesCategories()
    }
  }
}
</script>
<style lang="scss" scoped>
  #EstadisticasCategorias{
    position: relative;
    background-color: $white;
    min-height: 60vh;
    padding: 24px 28px;
  }

  .container{
    padding: 0;
  }

  .box-1{
    position: relative;
    padding-bottom: 10px;

    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: -30px;
      right: -30px;
      border-bottom: 1px solid #E8E8E8;
    }

    .row{
      display: flex;
      align-items: center;
    }

    h2{
      margin: 0 0 8px;
      color: #1C2C40;
      font-size: 15px;
      font-weight: $medium;
    }

    label{
      font-weight: regular;
    }
  }

  .box-3{
    margin-top: 40px;
    padding-bottom: 60px;

    h4{
      font-weight: normal;
      font-size: 16px;

      strong{
        font-weight: bold;
        color: $blue-light;
        display:block;
        font-size: 24px;
        margin:6px 0 30px;
      }

      &.two-cn{
        strong{
          color: #2F5EC4;
        }
      }
    }
  }

  .chart-data{
    position: relative;
    width: 234px;
    height: 234px;
    margin: 0 0 40px;
    & > div {
      position: relative;
      height: 100%;
    }
  }
  .chart-legend{
    padding:0;

    &.two-cn{
      strong{
        color: #2F5EC4;
      }
    }

    ul{
      margin-bottom: 30px;
    }

    li{
      list-style: none;
      margin-bottom: 20px;

      &:nth-child(1){
        span{
          background-color: #5C9BD5;
        }
      }

      &:nth-child(2){
        span{
          background-color: #EB7B34;
        }
      }

      &:nth-child(3){
        span{
          background-color: #A4A5A6;
        }
      }

      &:nth-child(4){
        span{
          background-color: #F9C40B;
        }
      }

      &:nth-child(5){
        span{
          background-color: #F7A591;
        }
      }

      &:nth-child(6){
        span{
          background-color: #42D0C2;
        }
      }

    }

    em{
      display: inline-block;
      width: 26px;
      font-style: normal;
    }

    strong{
      // font-weight: normal;
      color: #00adfc;
    }

    span{
      display: inline-block;
      width: 40px;
      @include roundAll(4);
      height: 20px;
      vertical-align: middle;
      margin: 0 16px;
      background-color: #EFEFEF;
    }
  }

</style>
