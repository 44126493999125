<template>
  <div v-if="type" v-html="annotation[type]"></div>
</template>

<script>
export default {
  name: "CalcAnnotation",
  data() {
    return {
      annotation: {
        prisma: '*Cálculos basado en el estudio de Balance de Carbono de los bosques de Catalunya realizado por el Departamento de Agricultura, Ganadería, Pesca y Alimentación de la Generalitat de Catalunya',
        pedrosa: '*Cálculo basado en el promedio de Absorciones acumuladas estimadas por árbol (t CO2/pie) de la Calculadora de proyectos de absorción de dióxido de carbono del Ministerio para la Transición Ecológica y el Reto Demográfico',
        maripol: '*Cálculo basado en el promedio de Absorciones acumuladas estimadas por árbol (t CO2/pie) de la Calculadora de proyectos de absorción de dióxido de carbono del Ministerio para la Transición Ecológica y el Reto Demográfico'
      }
    }
  },
  computed: {
    type()  {
      return this.$config.type
    }
  }
}
</script>

<style scoped>

</style>
